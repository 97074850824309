import { CallingState, StreamCall, StreamVideo, StreamVideoClient, useCall, useCallStateHooks, User, StreamTheme, SpeakerLayout, CallControls } from '@stream-io/video-react-sdk';
import '@stream-io/video-react-sdk/dist/css/styles.css';
import { useAuth } from './AuthContext';
import { Row, Col, Container, Card, Button, Image, Table, Form } from 'react-bootstrap';
import { StyledButton, StyledCard } from './StyledBootstrap';
import { useRef, useEffect, useState, useMemo } from 'react';
import { useTheme } from 'styled-components';
import logo_navy from './Images/logo_navy.png';
import logo_white from './Images/logo_white.png';

export default function TestVideoCall() {


  const containerRef = useRef(null);
  const themeMode = useTheme();

  useEffect(() => {
    const updateLayout = () => {

      const viewportHeight = window.innerHeight;


      if (containerRef.current) {
        // Push content below the navbar
        containerRef.current.style.minHeight = `${viewportHeight}px`; // Ensure it fills the remaining screen height
      }
    };

    updateLayout(); // Adjust layout on mount
    window.addEventListener('resize', updateLayout); // and on window resize

    return () => window.removeEventListener('resize', updateLayout); // Cleanup
  }, []); // Run once on mount



  return (
    <>
      <Container ref={containerRef} fluid className='d-flex flex-column justify-content-center h-100' style={{ backgroundColor: themeMode.background }}>
        <Row className='justify-content-center'>
          <Col className='col-lg-2 col-md-1'>
            <PromptComponent />


          </Col>
          <Col className='col-lg-10 col-md-11'>


            <VideoCallComponent />

          </Col>
        </Row>
      </Container>
    </>
  );
}


export const PromptComponent = () => {
  const [promptNumber, setPromptNumber] = useState(0);

  const prompts = ['Welcome to your Prospinity portfolio meeting! Use this section to guide the meeting, clicking the button below to move onto the next prompt.', 'Introduce yourself if you haven\'t already.', 'That\'s it from our side in terms of prompts. Feel free to talk more or, if you don\'t have anything more to say (which is fair), say goodbye and leave the call.'];
  const themeMode = useTheme();

  return (
    <StyledCard>
      <StyledCard.Body>


        {/*<img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem" className='mb-3' />*/}

        {prompts[promptNumber]}


        {promptNumber == 0 ?
          <StyledButton onClick={() => setPromptNumber(promptNumber + 1)} className='w-100 mt-4'>
            Get Started
          </StyledButton>
          : <></>}
        {promptNumber != 0 ?
          <div className='d-flex justify-content-center mt-4'>
            <StyledButton onClick={() => setPromptNumber(promptNumber - 1)} className='mr-2 w-50' style={{ backgroundColor: themeMode.background, color: themeMode.onBackground }}>
              Back
            </StyledButton>
            {promptNumber < prompts.length-1 ?

              <StyledButton onClick={() => setPromptNumber(promptNumber + 1)} className='ml-2 w-50'>
                Next
              </StyledButton> :



              <></>}

          </div>

          : <></>}

      </StyledCard.Body>

    </StyledCard>
  );

}


export const VideoCallComponent = () => {

  const { userSlug, userFirstName, chatID, chatToken } = useAuth();
  let token = chatToken;
  const apiKey = 'avstud5t3679'; // the API key can be found in the "Credentials" sectionl
  const callId = '5850c67d-0d42-4df1-8912-a4531461e4fb'; // the call id can be found in the "Credentials" section
  const user = {
    id: chatID,
    name: userFirstName,
    image: 'https://getstream.io/random_svg/?id=oliver&name=Oliver',
  };
  const client = useMemo(() => {
    return new StreamVideoClient({ apiKey, user, token: chatToken });
  }, [apiKey, user, chatToken]);

  const call = useMemo(() => {
    const callInstance = client.call('default', callId);
    callInstance.join({ create: true });
    return callInstance;
  }, [client, callId]);

  return (
    <StyledCard className='profile-joyrride'>
      <StyledCard.Body>
        <StreamVideo client={client}>
          <StreamCall call={call}>
            <MyUILayout />
          </StreamCall>
        </StreamVideo>
      </StyledCard.Body>
    </StyledCard>

  );
}



export const MyUILayout = () => {
  const { useCallCallingState } = useCallStateHooks();
  const callingState = useCallCallingState();

  if (callingState !== CallingState.JOINED) {
    return <div>Loading...</div>;
  }

  return (
    <StreamTheme>

      <SpeakerLayout participantsBarPosition='bottom' />
      <CallControls />

    </StreamTheme>
  );
};