//maybe put in the form control itself
//better grid navigation
//border selected elements with something

import React, { useState, useEffect, useRef } from 'react';
import { FormControl, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { stringToColor } from './CriticalFunctions';
import { useTheme } from 'styled-components';
import { StyledFormLabel, StyledFormControl, StyledFormGroup } from './StyledBootstrap';
import { myAxiosInstance } from './axiosConfig';

const bubbleStyle = (color) => ({
  display: 'inline-block',
  padding: '5px 10px',
  margin: '5px',
  borderRadius: '15px',
  backgroundColor: color,
  color: 'black',
  fontSize: '0.8rem',
});

const closeButtonStyle = {
  marginLeft: '0.3rem',
  cursor: 'pointer'
};

const suggestionListStyle = {
  listStyleType: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  flexWrap: 'wrap'
};






function SingleSelect({ endpoint, value, onChange, label, extra="", autofocus=false, other=false, ...props }) {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  console.log("Value", value);
  const [selectedItems, setSelectedItems] = useState(
    value[0]==="" ? [] : value.map(item => ({ name: item, color: stringToColor(item) }))
  );
  console.log("Single Select ", selectedItems);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const fetchOptions = async (query) => {
    try {
      console.log("happening!");
      const response = await myAxiosInstance.get('/api/' + endpoint + '/' + '?q=' + query);
      console.log(response);
      return response.data;
      /*return endpoint.filter(item =>
        item.label.toLowerCase().startsWith(query.toLowerCase())

      );*/


    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const themeMode = useTheme();
  const firstInputRef = useRef(null);
    useEffect(() => {
        // Focus the appropriate input field based on the step
        if (firstInputRef.current && autofocus) {
            firstInputRef.current.focus();
        }
    }, []);


  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (inputValue) {

        fetchOptions(inputValue).then(data => {
            console.log("data");
            console.log(data);

            const filteredData = data.filter(item =>
            !selectedItems.some(selectedItem => selectedItem.name === item.name)
          );
          console.log("filtered data");
          console.log(filteredData)
          if (other)
            {
              setSuggestions([...filteredData, {name: "Other"}]);
            }
            else{
              setSuggestions(filteredData);
            }
          
        });
      } else {
        setSuggestions([]);
      }
    }, 100); // 300ms debounce time

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [inputValue]);



  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setActiveSuggestionIndex(0);
  };

  const handleSelect = (item) => {
    if (!selectedItems.some(selectedItem => selectedItem.name === item.name)) {
      const updatedItems = [{ ...item, color: stringToColor(item.name) }];
      setSelectedItems(updatedItems);
      setInputValue('');
      setActiveSuggestionIndex(0);
      onChange(updatedItems.map(item => item.name));
    }

    /*const updatedItems = [...selectedItems, { ...item, color: stringToColor(item.label) }];
    setSelectedItems(updatedItems);
    setInputValue('');
    //setSuggestions([]);
    setActiveSuggestionIndex(0);
    onChange(updatedItems.map(item => item.label)); // Call the onChange prop with new value*/
  };


  const handleRemoveItem = (name) => {
    const updatedItems = selectedItems.filter(item => item.name !== name);
    setSelectedItems(updatedItems);
    onChange(updatedItems.map(item => item.name)); // Update the parent component
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (suggestions.length > 0)
      {
        handleSelect(suggestions[activeSuggestionIndex]);
      }
    } else if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
      setActiveSuggestionIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
      setActiveSuggestionIndex((prevIndex) => (prevIndex + suggestions.length - 1) % suggestions.length);
    }
  };

  return (
    
      
      <div>

        <StyledFormControl
          type="text"
          value={inputValue}
          placeholder={label}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          ref={firstInputRef}
          {...props}
        />
        {suggestions.length > 0 && (
          <ul style={suggestionListStyle}>
            {suggestions.map((item, index) => (
              <li key={index}
                style={{
                  display: 'flex', // Using flex to center the content
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginBottom: '5px',
                }}
                onClick={() => handleSelect(item)}
              >
                <span
                  style={{
                    ...bubbleStyle(stringToColor(item.name)),
                    cursor: 'pointer',
                    border: index === activeSuggestionIndex ? `2px solid ${themeMode.onSurface}` : 'none',
                    boxShadow: index === activeSuggestionIndex ? '0px 0px 10px rgba(0, 0, 0, 0.2)' : 'none',
                  }}
                >
                  {item.name}
                </span>
              </li>
            ))}
          </ul>


        )}
        <div>
          {selectedItems.map((item, index) => (
            <span key={index} style={bubbleStyle(item.color)}>
              {item.name}
              <span
                style={closeButtonStyle}
                onClick={() => handleRemoveItem(item.name)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </span>
          ))}
        </div>

      </div>
    
  );
}

export default SingleSelect;