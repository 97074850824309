import { StyledModal, StyledButton, StyledParagraph } from "./StyledBootstrap";
import { useAuth } from "./AuthContext";

function SigningRecap({ show, tutorialPart, onNext, onSkip, onBack, poolDetails }) {
  const { userFirstName } = useAuth();

  const renderContent = () => {
    switch (tutorialPart) {
      case 1:
        return (
          <>
            <StyledParagraph>
                Below is a recap of the most important things to remember about your Success Pooling Agreement! For more questions, please see our <a href="/dashboard/faqs" target="_blank" rel="noopener noreferrer" title="Right-click and select 'Open Link in New Tab'"><u>FAQs</u></a>.
            </StyledParagraph>
            <StyledParagraph>
              <strong>Terms: </strong> This pool has {poolDetails.member_count} members, a {poolDetails.contribution_percent}% rate, and a {poolDetails.time_horizon} year horizon.
            </StyledParagraph>
            <StyledParagraph>
              <strong>Service Fee: </strong> Our service fee is {poolDetails.contribution_percent * 0.05}% (5% of the {poolDetails.contribution_percent}% Rate). This helps us cover costs such as verifying earnings every year and managing the financial infrastructure.
            </StyledParagraph>
            <StyledParagraph>
              <strong>Post-Graduation Kick-In: </strong> The contribution term only kicks in when you leave school. If you have people who are a grade below you in your pool, it’s fair to everyone, because they only start their term when they start earning (so older members won't "contribute" for a longer period than younger members).
            </StyledParagraph>
            <StyledParagraph>
              <strong>Trial Period: </strong> If you are on the fence about this pool and need more time to decide, go ahead and join! Every member has a month-long trial period to leave the pool if they change their mind.
            </StyledParagraph>
            <StyledParagraph>
              <strong>Confidentiality: </strong> Accessing the Success Pooling Agreement is a privilege. It is Prospinity’s intellectual property and may not be shared or used for commercial purposes. For full details, see our <a href="/agreement-confidentiality" target="_blank" rel="noopener noreferrer" title="Right-click and select 'Open Link in New Tab'"><u>Confidentiality and Intellectual Property Agreement</u></a>.
            </StyledParagraph>
          </>
        );
      default:
        return <StyledParagraph>Unknown part!</StyledParagraph>;
    }
  };

  const renderTitle = () => {
    switch (tutorialPart) {
      case 1:
        return <StyledParagraph>Overview of {poolDetails.name}'s Success Pooling Agreement.</StyledParagraph>;
      default:
        return <StyledParagraph>Unknown part!</StyledParagraph>;
    }
  };

  return (
    <StyledModal show={show} onHide={onSkip} centered>
      <StyledModal.Header closeButton>
        <StyledModal.Title>{renderTitle()}</StyledModal.Title>
      </StyledModal.Header>
      <StyledModal.Body>{renderContent()}</StyledModal.Body>
      <StyledModal.Footer>
        {tutorialPart > 1 && (
          <StyledButton variant="secondary" onClick={onBack}>
            Back
          </StyledButton>
        )}
        <StyledButton variant="primary" onClick={onNext}>
          {tutorialPart < 1 ? "Next" : "View Agreement"}
        </StyledButton>
      </StyledModal.Footer>
    </StyledModal>
  );
}

export default SigningRecap;