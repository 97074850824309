import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';


const SideBarLink = ({ children, label, to, active, setShowReferModal }) => {
    const themeMode = useTheme();
    const navigate = useNavigate();

    return (
        <div className="nav-item d-flex align-items-center justify-content-start py-2 mb-2" style={{
            borderRadius: '1rem',
            padding: '10px 15px',
            cursor: 'pointer',
            color: active ? themeMode.onPrimary : themeMode.onSurface,
            transition: 'background-color 0.3s ease',
            backgroundColor: active ? themeMode.primary : 'transparent'
        }}
            onMouseEnter={(e) => !active && (e.currentTarget.style.backgroundColor = themeMode.primaryVariant) && (e.currentTarget.style.color = themeMode.onPrimary)}
            onMouseLeave={(e) => !active && (e.currentTarget.style.backgroundColor = 'transparent') && (e.currentTarget.style.color = themeMode.onSurface)}
            onClick={() => {
                if (to==="/dashboard/invite")
                {
                    setShowReferModal(true);
                }
                else{
                    navigate(to);
                }
                
            }}
        >
            {children}
            <span>{label}</span>
        </div>
    );
};




export default SideBarLink;