//fix button centering

//add the slug to captain
//make click hoverable
//change so that user_status not status
import { useRef } from "react";
import { Card, ListGroup, Row, Accordion, Button, Col, Container } from "react-bootstrap";
import PoolDeadlineTimer from "./PoolDeadlineTimer";
import { useNavigate } from "react-router-dom";
import { StyledBorderCard, StyledButton, StyledAccordion } from "./StyledBootstrap";
import { useAuth } from "./AuthContext";
import './Pool.css';
import { pastDeadline, stylePoolSlug, convertPackageToGreek } from "./CriticalFunctions";
import { useTheme } from "styled-components";


// Cards for the pools page
const PoolPreviewCard = ({ data }) => {

  const { accountStage } = useAuth();
  const themeMode = useTheme();

  const cardRef = useRef(null);


  const buttonOptions = [
    { text: 'Accept Invite' },
    { text: 'Invite Accepted' },
    { text: 'View Pool' },
    { text: 'Manage Pool' },
    { text: 'View Agreement' },
    { text: 'Request' },
    { text: 'Verify Account' },
    { text: 'Evaluate' },
    { text: 'Message' }
  ];
  const navigate = useNavigate();


  const profilePictureStyle = {
    width: '4rem', // Adjust width for less height
    height: '4rem', // Adjust height for less height
    borderRadius: '50%',
    marginRight: '0.5rem'
  };



  let buttonOptionsIndex = 5; // Updated logic using the local variable
  if (data.user_status === "invited_not_accepted") {
    buttonOptionsIndex = 0;
  } else if (data.user_status === "invited_accepted") {
    if (data.is_captain) {
      buttonOptionsIndex = 3;
    } else {
      buttonOptionsIndex = 1;
    }
  } else if (data.user_status === "accepted_not_signed") {
    if (accountStage == 3) {
      if (data.evaluate) {
        buttonOptionsIndex = 7;
      }
      else {
        buttonOptionsIndex = 2;
      }

    }
    else {
      buttonOptionsIndex = 5;
    }
  } else if (data.user_status === "accepted_signed") {
    buttonOptionsIndex = 4;
  }
  else if (data.user_status === "member") {
    buttonOptionsIndex = 8;
  }

  const handleDeadlineExpiry = () => //need to change (maybe reload api call based on slug)
  {
    return null;
  };

  const handleHeaderClick = () => {
    navigate('/dashboard/pool/' + data.slug);
  };


  const handleButtonClick = (event) => //change this
  {
    event.stopPropagation();
    navigate('/dashboard/pool/' + data.slug, { state: { actionButtonClicked: true } });
  }


  const handleCaptainClick = () => {
    if (data.hasOwnProperty('captain_slug')) {
      navigate('/dashboard/user/' + data.captain_slug);
    }
  }

  return (

    <>
      <StyledBorderCard ref={cardRef} style={{
        width: '21rem',
        transition: 'transform 0.3s ease',
        transform: 'scale(1)',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        marginBottom: '0rem', // Adjusted margin bottom to reduce space between cards
        cursor: 'pointer'

      }}
        onMouseEnter={() => {
          cardRef.current.style.transform = 'scale(1.03)';
          cardRef.current.style.boxShadow = '0 0 20px rgba(0,0,0,0.2)';
        }}
        onMouseLeave={() => {
          cardRef.current.style.transform = 'scale(1)';
          cardRef.current.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
        }}
        onClick={handleHeaderClick}>
        <StyledBorderCard.Body style={{ marginTop: '-0.5rem' }}>
          <div className='d-flex flex-column justify-content-center'>
            <div className="d-flex align-items-center">
              <img
                src={'https://storage.googleapis.com/prosp_profile_img/profile_pictures/main_profile_picture_prospinity-captain-O4zW.png'}
                alt={"Preview"}
                onError={(e) => { e.target.src = 'https://storage.googleapis.com/prosp_profile_img/profile_pictures/main_profile_picture_prospinity-captain-O4zW.png'; }}
                style={profilePictureStyle}
              />
              <div className='d-flex flex-column justify-content-center' style={{ minWidth: 0, width: '100%' }}>
                <div style={{
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '1.3rem',
                  marginTop: '0.3em',
                  minWidth: 0
                }}>
                  <strong style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                    onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
                    onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                    onClick={() => navigate('/dashboard/pool/' + data.slug)}
                    >{data.name}</strong>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '-0.1rem' }}>
                  <div
                    style={{
                      width: '0.5rem',
                      height: '0.5rem',
                      backgroundColor: pastDeadline(data.sign_deadline) ? 'green' : 'orange',
                      borderRadius: '50%',
                      marginRight: '0.2rem',
                    }}
                  />
                  <div
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      minWidth: 0,
                      fontSize: '0.8rem',
                    }}
                  >
                    
                    {/*pastDeadline(data.sign_deadline) ? <strong>Status: </strong> : pastDeadline(data.invitation_deadline) ? <strong>Sign Deadline: </strong> : <strong>Invitation Deadline: </strong>*/}
                    <PoolDeadlineTimer invitationDeadline={data.invitation_deadline} signDeadline={data.sign_deadline} onDeadlineExpire={handleDeadlineExpiry}></PoolDeadlineTimer>                  </div>
                </div>
                {/*<div style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis', whiteSpace: 'nowrap', minWidth: 0, fontSize: '0.8rem'
                }}>
                  <i>{"20 members"}</i>
                </div>*/}

              </div>
            </div>
            <div className='mt-1 mx-1' style={{ fontSize: '0.9rem' }}>
              <strong>Members:</strong> {data.member_count} people
            </div>
            <div className='mt-1 mx-1' style={{ fontSize: '0.9rem' }}>
              <strong>Percentage:</strong> {data.contribution_percent}%
            </div>
            <div className='mt-1 mx-1' style={{ fontSize: '0.9rem' }}>
              <strong>Horizon:</strong> {data.time_horizon} years
            </div>

            <div className='w-100 d-flex justify-content-center mx-1 mt-3'>
              <StyledButton style={{ fontSize: '0.8rem', minWidth: '100%' }} onClick={handleButtonClick}>
                {buttonOptions[buttonOptionsIndex].text}
              </StyledButton>
            </div>

          </div>
        </StyledBorderCard.Body>
      </StyledBorderCard >
      
    </>
  );
};

export default PoolPreviewCard;


// Cards for the home page
export const HomePoolPreviewCard = ({ data }) => {
  const { accountStage } = useAuth();
  const themeMode = useTheme();
  const cardRef = useRef(null);
  const navigate = useNavigate();
  const isInvitationPeriod = !pastDeadline(data.invitation_deadline)

  const profilePictureStyle = {
    width: '4rem',
    height: '4rem',
    borderRadius: '50%',
    marginTop: '0.5rem',
    marginRight: '1rem'
  };

  const handleHeaderClick = () => {
    navigate('/dashboard/pool/' + data.slug);
  };

  const handleButtonClick = (event) => {
    event.stopPropagation();
    navigate('/dashboard/pool/' + data.slug, { state: { actionButtonClicked: isInvitationPeriod ? true : false} });
  };

  const handleCaptainClick = () => {
    if (data.hasOwnProperty('captain_slug')) {
      navigate('/dashboard/user/' + data.captain_slug);
    }
  };

  const handleDeadlineExpiry = () => //need to change (maybe reload api call based on slug)
  {
    return null;
  };


  return (
    <StyledBorderCard
      ref={cardRef}
      style={{
        width: '21rem',
        transition: 'transform 0.3s ease',
        transform: 'scale(1)',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        marginBottom: '0rem',
        cursor: 'pointer'
      }}
      onMouseEnter={() => {
        cardRef.current.style.transform = 'scale(1.03)';
        cardRef.current.style.boxShadow = '0 0 20px rgba(0,0,0,0.2)';
      }}
      onMouseLeave={() => {
        cardRef.current.style.transform = 'scale(1)';
        cardRef.current.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
      }}
      onClick={handleHeaderClick}
    >
      <StyledBorderCard.Body style={{ marginTop: '-1.0rem' }}>
        <div className='d-flex flex-column justify-content-center'>
          <div className="d-flex align-items-center">
            <img
              src={isInvitationPeriod ? data.captain_picture : 'https://storage.googleapis.com/prosp_profile_img/profile_pictures/main_profile_picture_prospinity-captain-O4zW.png'}
              alt={"Preview"}
              onError={(e) => { e.target.src = 'https://storage.googleapis.com/prosp_profile_img/profile_pictures/main_profile_picture_prospinity-captain-O4zW.png'; }}
              style={profilePictureStyle}
              onClick={(event) => {
                if (isInvitationPeriod){
                  event.stopPropagation();  // Prevent the click from bubbling to the card's onClick
                  navigate('/dashboard/user/' + data.captain_slug);
                }
              }}
            />
            <div className='d-flex flex-column justify-content-center' style={{ minWidth: 0, width: '100%'}}>
              <div style={{
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '1.3rem',
                marginTop: '0.3em',
                minWidth: 0
              }}>
                <strong
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                  onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
                  onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                  onClick={(event) => {
                    if (isInvitationPeriod){
                      event.stopPropagation();  // Prevent the click from bubbling to the card's onClick
                      navigate('/dashboard/user/' + data.captain_slug);
                    }
                  }}
                >
                  {isInvitationPeriod ? `${data.captain_first_name} ${data.captain_last_name}` : data.name}
                </strong>
              </div>
              

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '2.5rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: isInvitationPeriod ? '-0.1rem' : '-0.3rem' }}>
                  <div style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    minWidth: 0,
                    fontSize: '1.0rem',
                  }}>
                    {isInvitationPeriod ? (
                      <>
                        {data.captain_university}
                        {data.captain_company ? (
                          <>
                            <span style={{
                              margin: '0 0.2rem',
                              lineHeight: '1',
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              transform: 'translateY(-10%)'
                            }}>•</span>
                            {data.captain_company}
                          </>
                        ) : null}
                      </>
                    ) : (
                      <>
                        Members: {data.member_count} {data.member_count > 1 ? "people" : "person"}
                      </>
                    )}
                  </div>
                </div>

                {isInvitationPeriod ? null : (
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '-0.1rem' }}>
                    <div
                      style={{
                        width: '0.5rem',
                        height: '0.5rem',
                        backgroundColor: 'orange',
                        borderRadius: '50%',
                        marginRight: '0.2rem',
                      }}
                    />
                    <div style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      minWidth: 0,
                      fontSize: '0.8rem',
                    }}>
                      <span style={{ fontStyle: 'italic' }}>
                        pending
                      </span>
                    </div>
                  </div>
                )}
              </div>


              
            </div>
          </div>

          <div style={{ marginTop: '1rem', fontSize: '1rem' }}>
            {isInvitationPeriod ? (
              <>
                Invited you to join a pool, <strong>{data.name}</strong>.
              </>
            ) : (
              <>
                You have <strong><PoolDeadlineTimer invitationDeadline={data.invitation_deadline} signDeadline={data.sign_deadline} onDeadlineExpire={handleDeadlineExpiry} /></strong> to join.
              </>
            )}
          </div>

          <div className='w-100 d-flex justify-content-center mt-3'>
            <StyledButton style={{ fontSize: '0.8rem', minWidth: '100%' }} onClick={handleButtonClick}>
              {'View Pool'}
            </StyledButton>
          </div>
        </div>
      </StyledBorderCard.Body>
    </StyledBorderCard>
  );
};
