import { StyledModal, StyledButton, StyledParagraph } from "./StyledBootstrap";
import { useAuth } from "./AuthContext";

function PoolRecapTutorial({ show, tutorialPart, onNext, onSkip, onBack, poolDetails }) {
  const { userFirstName } = useAuth();

  const renderContent = () => {
    switch (tutorialPart) {
      case 1:
        return (
          <>
            <StyledParagraph>
              Joining takes just a few minutes. Click <strong>Join Pool</strong> in the upper right hand corner to see the agreement and follow the steps there.
            </StyledParagraph>
          </>
        );
      default:
        return <StyledParagraph>Unknown part!</StyledParagraph>;
    }
  };

  const renderTitle = () => {
    switch (tutorialPart) {
      case 1:
        return <StyledParagraph>Congratulations! {poolDetails.captain_first_name} has invited you to their Success Pool {poolDetails.name}.</StyledParagraph>;
      default:
        return <StyledParagraph>Unknown part!</StyledParagraph>;
    }
  };

  return (
    <StyledModal show={show} onHide={onSkip} centered>
      <StyledModal.Header closeButton>
        <StyledModal.Title>{renderTitle()}</StyledModal.Title>
      </StyledModal.Header>
      <StyledModal.Body>{renderContent()}</StyledModal.Body>
      <StyledModal.Footer>
        {tutorialPart > 1 && (
          <StyledButton variant="secondary" onClick={onBack}>
            Back
          </StyledButton>
        )}
        <StyledButton variant="primary" onClick={onNext}>
          {tutorialPart < 1 ? "Next" : "Close"}
        </StyledButton>
      </StyledModal.Footer>
    </StyledModal>
  );
}

export default PoolRecapTutorial;