import React, { useEffect } from 'react';
import { myAxiosInstance } from './axiosConfig';

function BonusRedirect() {
    const targetLink = 'https://docs.google.com/forms/d/e/1FAIpQLSdoXnkGmVzdABhWXVY0eBGfa-ebndmeWyiffA3wgGEBqLVeTw/viewform';

    useEffect(() => {
        // Send the tracking event
        myAxiosInstance.post('/api/track/', { event_name: "Landed on bonus", event_data: {} })
            .then((res) => {
                console.log('Tracking event sent');
                // Delay the redirection to allow the tracking request to complete
                setTimeout(() => {
                    window.location.href = targetLink;
                }, 200);
            })
            .catch((err) => {
                console.log('Tracking event failed', err);
                // Redirect even if tracking fails
                window.location.href = targetLink;
            });
    }, []);

    return null; // Optionally, return null or a loading message while redirecting
}

export default BonusRedirect;
