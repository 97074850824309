import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { myAxiosInstance } from './axiosConfig';
import Loading from './Loading';
import { Row, Col, Container } from 'react-bootstrap';
import { StyledCard, StyledH5, StyledButton } from './StyledBootstrap';
import { useErrorBoundary } from "react-error-boundary";
import ScheduleSelector from 'react-schedule-selector';
import { useTheme } from 'styled-components';
import { useMediaQuery } from "react-responsive";

//display without selecting, propose times, select time

function Scheduler({ status, availability, selectedTime, setSelectedTime, proposedTimes, setProposedTimes }) {
    console.log("proposedTimes", proposedTimes);
    const themeMode = useTheme();


    const isMdOrLarger = useMediaQuery({ minWidth: 768 });

    const [nextSet, setNextSet] = useState(false);
    const [startDate, setStartDate] = useState(new Date());

    useEffect(() => {
        const currentDate = new Date(); // Store the current date (now)

        if (nextSet) {
            // Add 4 days when nextSet is true
            const futureDate = new Date(currentDate);
            futureDate.setDate(futureDate.getDate() + 4);
            setStartDate(futureDate);
        } else {
            // Reset to current date when nextSet is false
            setStartDate(currentDate);
        }

    }, [nextSet]);

    const handleProposedTimesChange = newSchedule => {
        const now = new Date(); // Get the current date and time

        // Filter out any times that are in the past
        const validTimes = newSchedule.filter(time => time >= now);

        // Update the proposed times with only valid (future) times
        setProposedTimes(validTimes);
        //setProposedTimes(newSchedule);
    };

    const handleDisplayTimesChange = newSchedule => {
        console.log("hey");
    }

    const handleTimeChange = (newTime) => {
        try {

            console.log("selectedTime[0]", selectedTime);
            console.log("newTime[0]", newTime);
            if (selectedTime.length == 0) //setting completely new time
            {
                if (availability.some((availableTime) => (availableTime.toString() === newTime[0].toString()))) //maybe these not needed
                {
                    const now = new Date();
                    if (newTime[0] >= now) {
                        setSelectedTime([newTime[0]]);
                    }

                }

            }
            else if (!(newTime.length === 0) && (selectedTime.length === 1 && selectedTime[0].toString() !== newTime[1].toString())) //time already selected, switching
            {
                if (availability.some((availableTime) => (availableTime.toString() === newTime[1].toString()))) {
                    const now = new Date();
                    if (newTime[0] >= now) {
                        setSelectedTime([newTime[1]]);
                    }
                }
                //setSelectedTime([newTime[1]]);
            }
            else {
                setSelectedTime([]); // Deselect if the same time is clicked again
            }

        }
        catch (error) {
            console.log(error);
        }

    };



    const renderDateCellPropose = (time, selected, innerRef) => {

        const handleMouseEnter = (event) => {

            event.target.style.backgroundColor = themeMode.primaryVariant;

        };

        const handleMouseLeave = (event) => {

            if (selected) {
                event.target.style.backgroundColor = themeMode.primary;
            }
            else {
                event.target.style.backgroundColor = themeMode.background;
            }

            // Revert to original color

        };



        // Style for available time slots (highlighted)
        const availableStyle = {
            backgroundColor: selected ? themeMode.primary : themeMode.background, // Neon green for available slots
            cursor: 'pointer', // Allow interaction with available slots only
            transition: 'background-color 0.3s ease', // Smooth transition for hover effect
        };

        return (
            <div
                ref={innerRef}
                style={{
                    ...availableStyle,
                    width: '100%',
                    height: '100%',
                }}
                onMouseEnter={handleMouseEnter} // Hover starts
                onMouseLeave={handleMouseLeave} // Hover ends

            />
        );
    };


    const renderDateLabel = (date) => {
        const day = date.toLocaleDateString('en-US', { weekday: 'short' }); // Get day (e.g., Mon)
        const monthDay = date.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' }); // Get month and day (e.g., Sep 21)

        return (
            <div style={{ fontSize: '16px', color: themeMode.onSurface }} className='text-center'>
                {day} {monthDay}
                {/*<div>{monthDay}</div>*/}
            </div>
        );
    };

    const renderTimeLabel = (time) => {
        let formattedTime = time.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        });
        if (!isMdOrLarger) {
            formattedTime = time.toLocaleTimeString('en-US', {
                hour: 'numeric',
                hour12: true,
            });
        }


        return (
            <div style={{ fontSize: '14px', color: themeMode.onSurface }} className='text-right mr-2'>
                {formattedTime}
            </div>
        );
    };


    const renderDateCellDisplay = (time, selected, innerRef) => {
        // Check if the current time is in the available times list




        // Style for available time slots (highlighted)
        const availableStyle = {
            backgroundColor: selected ? themeMode.primary : themeMode.background, // Neon green for available slots
            cursor: 'not-allowed', // Allow interaction with available slots only
            transition: 'background-color 0.3s ease', // Smooth transition for hover effect
        };

        return (
            <div
                ref={innerRef}
                style={{
                    ...availableStyle,
                    width: '100%',
                    height: '100%',
                }}


            />
        );
    };


    /*const renderDateCellPick = (time, selected, innerRef) => {
        // Check if the current time is in the available times list
        const isAvailable = availability.some(
            (availableTime) => availableTime.getTime() === time.getTime()
        );

        const handleMouseEnter = (event) => {
            if (isAvailable) {
                event.target.style.border = '2px solid #000'; 
            }
        };

        const handleMouseLeave = (event) => {
            if (isAvailable) {
                event.target.style.border = selected ? '2px solid #000' : '1px solid #ddd'; 
            }
        };



        // Style for available time slots (highlighted)
        const availableStyle = {
            backgroundColor: isAvailable ? themeMode.primary : themeMode.background, // Neon green for available slots
            border: selected ? '2px solid #000' : '1px solid #ddd', // Highlight border for selected slot
            cursor: isAvailable ? 'pointer' : 'not-allowed', // Allow interaction with available slots only
            transition: 'background-color 0.3s ease', // Smooth transition for hover effect
        };

        return (
            <div
                ref={innerRef}
                style={{
                    ...availableStyle,
                    width: '100%',
                    height: '100%',
                }}
                onMouseEnter={handleMouseEnter} // Hover starts
                onMouseLeave={handleMouseLeave} // Hover ends
            />
        );
    };*/
    /*const renderDateCellPick = (time, selected, innerRef) => {
         // Check if the current time is in the available times list
         const isAvailable = availability.some(
             (availableTime) => availableTime.getTime() === time.getTime()
         );
     
         const handleMouseEnter = (event) => {
             if (isAvailable) {
                 event.target.style.border = '2px solid #000';
                 event.target.style.transform = 'scale(1.05)'; // Slight zoom effect
                 event.target.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.2)'; // Add subtle shadow
             }
         };
     
         const handleMouseLeave = (event) => {
             if (isAvailable) {
                 event.target.style.border = selected ? '2px solid #000' : '1px solid #ddd';
                 event.target.style.transform = 'scale(1)'; // Reset to original size
                 event.target.style.boxShadow = 'none'; // Remove shadow
             }
         };
     
         // Style for available time slots (highlighted)
         const availableStyle = {
             backgroundColor: isAvailable ? themeMode.primary : themeMode.background,
             border: selected ? '2px solid #000' : '1px solid #ddd',
             cursor: isAvailable ? 'pointer' : 'not-allowed',
             transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.3s ease', // Smooth transitions
         };
     
         return (
             <div
                 ref={innerRef}
                 style={{
                     ...availableStyle,
                     width: '100%',
                     height: '100%',
                 }}
                 onMouseEnter={handleMouseEnter} // Hover starts
                 onMouseLeave={handleMouseLeave} // Hover ends
             />
         );
     };*/
    const renderDateCellPick = (time, selected, innerRef) => {
        // Check if the current time is in the available times list
        const isAvailable = availability.some(
            (availableTime) => availableTime.getTime() === time.getTime()
        );

        const handleMouseEnter = (event) => {
            if (isAvailable) {
                event.target.style.border = '2px solid #ff9800'; // Orange border for hover
                event.target.style.transform = 'scale(1.1)'; // Slight zoom effect
                event.target.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.2)'; // Subtle shadow
            }
        };

        const handleMouseLeave = (event) => {
            if (isAvailable) {
                event.target.style.border = selected ? '2px solid #ff9800' : '1px solid #ddd'; // Stronger orange border for selected
                event.target.style.transform = selected ? 'scale(1.1)' : 'scale(1)'; // Keep zoom if selected
                event.target.style.boxShadow = 'none'; // Keep shadow if selected
            }
        };

        // Style for available time slots (highlighted)
        const availableStyle = {
            backgroundColor: isAvailable ? (selected ? themeMode.primary : themeMode.primary) : themeMode.background, // Slightly darker for selected
            border: selected ? '2px solid #ff9800' : '1px solid #ddd', // Stronger border for selected
            cursor: isAvailable ? 'pointer' : 'not-allowed',
            transform: selected ? 'scale(1.1)' : 'scale(1)', // Keep zoom if selected
            transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.3s ease, border 0.2s ease', // Smooth transitions
        };

        return (
            <div
                ref={innerRef}
                style={{
                    ...availableStyle,
                    width: '100%',
                    height: '100%',
                }}
                onMouseEnter={handleMouseEnter} // Hover starts
                onMouseLeave={handleMouseLeave} // Hover ends
            />
        );
    };


    return (
        <div>
            {!isMdOrLarger ? <div className='w-100 d-flex justify-content-end mb-1'>
                <StyledButton onClick={() => setNextSet(!nextSet)} style={{ fontSize: '0.8rem' }}>
                    {nextSet ? <>Back</> : <>Next 4 Days</>}
                </StyledButton>
            </div> : <></>}
            {status === "pick" ?
                <ScheduleSelector
                    selection={selectedTime}
                    numDays={isMdOrLarger ? 7 : 4}
                    startDate={startDate}
                    minTime={8}
                    maxTime={22}
                    hourlyChunks={1}
                    onChange={handleTimeChange}
                    timeFormat={'h:mma'}
                    renderDateCell={renderDateCellPick}
                    renderDateLabel={renderDateLabel}
                    renderTimeLabel={renderTimeLabel}
                /> : status === "propose" ?


                    <ScheduleSelector
                        selection={proposedTimes}
                        numDays={isMdOrLarger ? 7 : 4}
                        startDate={startDate}
                        minTime={8}
                        maxTime={22}
                        hourlyChunks={1}
                        onChange={handleProposedTimesChange}
                        timeFormat={'h:mma'}
                        renderDateCell={renderDateCellPropose}
                        renderDateLabel={renderDateLabel}
                        renderTimeLabel={renderTimeLabel}
                    />

                    : <ScheduleSelector
                        selection={proposedTimes}
                        numDays={isMdOrLarger ? 7 : 4}
                        startDate={startDate}
                        minTime={8}
                        maxTime={22}
                        hourlyChunks={1}
                        onChange={handleDisplayTimesChange}
                        timeFormat={'h:mma'}
                        renderDateCell={renderDateCellDisplay}
                        renderDateLabel={renderDateLabel}
                        renderTimeLabel={renderTimeLabel}
                    />}
        </div>


    )


}

export default Scheduler;