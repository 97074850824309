import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { StyledButton, StyledH4, StyledParagraph } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import logo_gradient from './Images/logo_gradient.png';
import logo_white from './Images/logo_white.png';
import logo_black from './Images/logo_black.png';
import logo_navy from './Images/logo_navy.png';
import Loading from './Loading';
import EmailVerificationSent from './EmailVerificationSent';
import { useAuth } from './AuthContext';
import { errorString } from './CriticalFunctions';


function EmailVerification() {
    const { verificationKey } = useParams();
    const themeMode = useTheme();
    const [message, setMessage] = useState("Verifying email...");
    const [verified, setVerified] = useState(false);
    const [isBottomLoading, setIsBottomLoading] = useState(true);
    const { handleLogin } = useAuth();
    const navigate = useNavigate();
    async function sendEmailVerificationKey(verificationKey, setMessage, setVerified) {
        try {
            const response = await axios.post('/prospinity-auth/email/verify/', { "key": verificationKey });
            setMessage("Email verified.");
            setVerified(true);
            window.location.href = "/dashboard";
            //window.open()
            //handleLogin(response);


        } catch (error) {
            setMessage("Something went wrong. Check to make sure you entered the right email and clicked on the right link.");
        }
        finally {
            setIsBottomLoading(false);
        }
    }

    useEffect(() => {
        sendEmailVerificationKey(verificationKey, setMessage, setVerified);
    }, [verificationKey]);

    return (
        <>
            {
                isBottomLoading ?
                    <Loading />
                    :
                    

                    <EmailVerificationSent>
                        {message==="Something went wrong. Check to make sure you entered the right email and clicked on the right link." ? <StyledParagraph className='mt-3'>{message}</StyledParagraph> :
                        <StyledParagraph className='mt-3'>Email successfully verified. Go to your <a href="/dashboard">dashboard</a> now.</StyledParagraph>}
                        </EmailVerificationSent>
            }
        </>


    );
}



export default EmailVerification;



/*
        <div className='d-flex flex-column justify-content-center align-items-center text-center vh-100 w-100' style={{ backgroundColor: themeMode.background }}>
            <StyledH4>Email Verification</StyledH4>
            <StyledH4>{message}</StyledH4>
            {verified && (
                <StyledH4>
                    <Link to="/signin">Go to Sign In</Link>
                </StyledH4>
            )}

        </div>*/

/*
<div className='d-flex flex-column justify-content-center align-items-center text-center vh-100 w-100' style={{ backgroundColor: themeMode.background }}>
        <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem"/>
        <StyledParagraph className='mt-3'>{message}</StyledParagraph>
        {message==="Email verified." ? <StyledButton onClick={() => navigate('/signin')}>Go to Sign In</StyledButton> : <></>}
        
        </div>*/