import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Row, Col, Container, InputGroup, Dropdown } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import { StyledButton, StyledCard, StyledFormControl, StyledFormGroup, StyledFormLabel, StyledForm, StyledParagraph } from './StyledBootstrap';
import logo from './Images/Logo1.png';
import logo_gradient from './Images/logo_gradient.png';
import logo_white from './Images/logo_white.png';
import logo_black from './Images/logo_black.png';
import logo_navy from './Images/logo_navy.png';
import axios from 'axios';
import EmailVerificationSent from './EmailVerificationSent';

function PasswordReset() {
    const themeMode = useTheme();
    const { verificationKey, slug } = useParams();
    const [message, setMessage] = useState("");
    const [changed, setChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [password, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');

    const [errors, setErrors] = useState({ new_password1: false, new_password2: false, non_field_errors: false });


    const firstInputRef = useRef(null);
    useEffect(() => {
        // Focus the appropriate input field based on the step
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, []);

    async function sendPasswordVerificationKey(e) {
        e.preventDefault();
        try {
            setIsLoading(true);
            console.log({ new_password1: password, new_password2: verifyPassword, slug: slug, token: verificationKey });
            const response = await axios.post('/prospinity-auth/password/reset/confirm/', { new_password1: password, new_password2: verifyPassword, slug: slug, token: verificationKey });
            console.log(response);
            setMessage("Password Changed. Please login again with new your new password.");
            setChanged(true);
        } catch (error) {
            if (error.response && error.response.data) {
                // Construct the error list JSX and pass it to setMessage
                let tempErrors = { new_password1: false, new_password2: false, non_field_errors: false }
                for (const [key, value] of Object.entries(error.response.data)) {
                    tempErrors = { ...tempErrors, [key]: true };
                }
                setErrors(tempErrors);

                console.log(error);
                console.error("Authentication error:", error);
                if (tempErrors.non_field_errors) {
                    console.log("in this if statement");
                    setMessage(error.response.data.non_field_errors);
                }

            }
            else {
                setMessage("Cannot send reset password.");
            }
            /*console.log(error);
            setMessage(Object.values(error.response.data)[0][0]);*/
        }
        finally {
            setIsLoading(false);
        }
    }


    return (
        changed ? <EmailVerificationSent><StyledParagraph className='mt-3'>Password changed. Please <Link to="/signin">sign in</Link> with your new password.</StyledParagraph></EmailVerificationSent> :
            <div>
                <Container fluid style={{ height: '100vh', backgroundColor: themeMode.background }} className='d-flex flex-column justify-content-center'>
                    <Row className='justify-content-center'>
                        <Col className='col-lg-4 col-md-6'>

                            <StyledCard>
                                <StyledCard.Body>
                                    <Row className='justify-content-center mb-4'>
                                        <Col md={10} className="text-center">
                                            <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem" />
                                        </Col>
                                    </Row>
                                    <StyledForm onSubmit={(e) => sendPasswordVerificationKey(e)}>
                                        <StyledFormGroup controlId="formBasicPassword">
                                            <StyledFormControl
                                                type="password"
                                                placeholder="New Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                ref={firstInputRef}
                                                hasError={errors.new_password1}
                                                onClick={() => setErrors({ ...errors, new_password1: false })}
                                            />
                                            {errors.new_password1 ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please enter a valid password.</StyledParagraph> : <></>}

                                        </StyledFormGroup>
                                        <StyledFormGroup controlId="formVerifyPassword">
                                            <StyledFormControl
                                                type="password"
                                                placeholder="Verify New Password"
                                                value={verifyPassword}
                                                onChange={(e) => setVerifyPassword(e.target.value)}
                                                hasError={errors.new_password2}
                                                onClick={() => setErrors({ ...errors, new_password2: false })}
                                            />
                                            {errors.new_password2 ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please ensure that this matches your new password.</StyledParagraph> : <></>}
                                        </StyledFormGroup>
                                        <StyledButton variant="primary" type="submit" className="mt-1 w-100">
                                            Change Password
                                        </StyledButton>
                                    </StyledForm>
                                    <div className='text-center'>
                                        {isLoading && <StyledParagraph>Changing Password...</StyledParagraph>}
                                        {message && <StyledParagraph>{message}</StyledParagraph>}
                                    </div>
                                </StyledCard.Body>
                            </StyledCard>
                        </Col>
                    </Row>
                </Container>

            </div>);
}


export default PasswordReset;
