import { useLocation } from 'react-router-dom'; // To get the current path
import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserGroup, faBullseye, faCreditCard, faMessage, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import logo_white_p from './Images/logo_white_p.png';
import logo_black_p from './Images/logo_black_p.png';
import logo_navy_p from './Images/logo_navy_p.png';

function BottomNavbarSmallScreen() {
    const themeMode = useTheme();
    const location = useLocation(); // Get the current URL path

    // Helper function to check if a path is active
    const isActive = (path) => location.pathname === path;

    const navigate = useNavigate();

    // Style for active and inactive states
    const getNavItemStyle = (active) => ({
        color: active ? themeMode.primary : themeMode.onSurface,
        boxShadow: active ? '0px -2px 10px rgba(0, 0, 0, 0.2)' : 'none',
        borderTop: active ? `2px solid ${themeMode.primary}` : 'none',
        fontWeight: active ? 'bold' : 'normal',
        padding: '1rem',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
    });

    return (
        <div style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: themeMode.surface, display: 'flex', justifyContent: 'space-around', zIndex: 1030 }}>
    <div style={getNavItemStyle(isActive('/dashboard/home'))} onClick={() => navigate("/dashboard/home")}>
        <FontAwesomeIcon icon={faHome} />
        <div style={{ color: 'inherit', fontSize: '0.7rem' }}>Home</div>
    </div>
    <div style={getNavItemStyle(isActive('/dashboard/portfolio'))} onClick={() => navigate("/dashboard/portfolio")}>
        <FontAwesomeIcon icon={faUserGroup} />
        <div style={{ color: 'inherit', fontSize: '0.7rem' }}>Portfolio</div>
    </div>
    <div style={getNavItemStyle(isActive('/dashboard/pools'))} onClick={() => navigate("/dashboard/pools")}>
        {/*<FontAwesomeIcon icon={faBullseye} />*/}
        <img src={isActive('/dashboard/pools') ? logo_navy_p : themeMode.theme == 'dark' ? logo_white_p : logo_black_p} height="17rem" />
        <div style={{ color: 'inherit', fontSize: '0.7rem' }}>Pools</div>
    </div>
    <div style={getNavItemStyle(isActive('/dashboard/payments'))} onClick={() => navigate("/dashboard/payments")}>
        <FontAwesomeIcon icon={faCreditCard} />
        <div style={{ color: 'inherit', fontSize: '0.7rem' }}>Finances</div>
    </div>
    <div style={getNavItemStyle(isActive('/dashboard/chat-page'))} onClick={() => navigate("/dashboard/chat-page")}>
        <FontAwesomeIcon icon={faMessage} />
        <div style={{ color: 'inherit', fontSize: '0.7rem' }}>Chat</div>
    </div>
</div>
    );
}

export default BottomNavbarSmallScreen;