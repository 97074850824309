import Loading from "./Loading";
import { useErrorBoundary } from "react-error-boundary";
import UserPreviewCard from "./UserPreviewCard";
import { myAxiosInstance } from "./axiosConfig";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { StyledCard, StyledH5 } from "./StyledBootstrap";
import { useTheme } from "styled-components";
import { useMediaQuery } from "react-responsive";
import PoolPreviewCard from "./PoolPreviewCard";
import { useAuth } from "./AuthContext";
import { StyledButton } from "./StyledBootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faQuestion, faUser, faSwimmer, faFileInvoiceDollar, faSignOut, faChartLine, faCreditCard, faCheck, faSearch, faBell, faPlus, faGear, faPeopleGroup, faSwimmingPool, faEnvelope, faCircle, faBullseye } from '@fortawesome/free-solid-svg-icons'; // Example icons
import { useNavigate } from "react-router-dom";

const MyPools = () => {


    const [isBottomLoading, setIsBottomLoading] = useState(true);
    const [pools, setPools] = useState([]);
    const [featuredPools, setFeaturedPools] = useState([]);
    const [bottomReload, setBottomReload] = useState(0);
    const themeMode = useTheme();
    const { showBoundary } = useErrorBoundary();
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });
    const { poolingRequested, setPoolingRequested, poolingActivated, setPoolingActivated } = useAuth();
    const [activatePoolingButtonLoading, setActivatePoolingButtonLoading] = useState(false);
    const navigate = useNavigate();


    const handleActivatePoolingClick = async () => {
        try {
            setActivatePoolingButtonLoading(true);
            const response = await myAxiosInstance.put('/api/enable-pooling/', {pooling_requested: true});
            setPoolingRequested(true);
        }
        catch (error) {
            console.error('Error sending portfolio request', error.response.data);
        }
        finally {
            setActivatePoolingButtonLoading(false);
        }

    };




    useEffect(() => {
        const getPools = async () => {
            try {
                setIsBottomLoading(true);

                const response = await myAxiosInstance.get('/api/user-pools/');
                const response1 = await myAxiosInstance.get('/api/featured-pools/');
                console.log("POOLS RESPONSE", response.data);
                setPools(response.data);
                setFeaturedPools(response1.data);


            } catch (error) {
                console.error('Error fetching pools details', error.response.data);
                showBoundary(error);
            } finally {
                setIsBottomLoading(false);
            }
        };

        
        getPools();


    }, [bottomReload]);


    return (
        isBottomLoading ? <Loading />
            :
            
            <div className='d-flex justify-content-center' style={{ backgroundColor: themeMode.background, minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>
                <div className='mx-2' style={{ minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>

                {!poolingActivated ? 
                <>
                <div className="mt-3">
                        <StyledH5><strong>Activate Pooling</strong></StyledH5>
                    </div>
                    <StyledCard style={{ position: 'relative' }}>
                        <StyledCard.Body>
                            <div>
                                We are currently only allowing a select group of students to access success pools. If you are interested, click activate pooling, and we will screen your profile and reach out if you are selected.
                            </div>

                            {poolingRequested ? <StyledButton className='mt-2' style={{ backgroundColor: themeMode.background, color: themeMode.onBackground }}>
                                Applied
                            </StyledButton> :
                                <StyledButton onClick={handleActivatePoolingClick} className='mt-2'>
                                    {activatePoolingButtonLoading ? <Loading /> : <>Activate Pooling</>}
                                </StyledButton>}

                        </StyledCard.Body>

                    </StyledCard>
                    </> : <></>}

                    {poolingActivated ? 
                    <>
                    <div className="d-flex justify-content-end mt-3" style={{marginBottom: '-1rem'}}>
                    <StyledButton onClick={() => navigate("/dashboard/create-pool")} className='px-3' style={{fontSize: '0.8rem'}}><FontAwesomeIcon icon={faPlus} className="mr-1"/>Create Pool</StyledButton>
                    </div>
                    <div className="d-flex justify-content-between">
                        <StyledH5><strong>My Pools</strong></StyledH5>
                    </div>
                    <StyledCard style={{ position: 'relative' }}>
                        {pools.length == 0 ? <StyledCard.Body>You haven't received any pool invites. Make sure to add people to your portfolio from Daily Picks.</StyledCard.Body> :
                            <>
                                <StyledCard.Body className='d-flex' style={{
                                    overflowX: 'auto',
                                    whiteSpace: 'nowrap',
                                    gap: '1rem',
                                    paddingRight: '1.5rem',
                                    minWidth: 0,
                                    width: '100%'  // Ensure there's space on the right for the gradient
                                }}>

                                    {pools.map((pool) => (
                                        <div className="mx-1 my-1">

                                            <PoolPreviewCard data={pool} />
                                        </div>
                                    ))}
                                </StyledCard.Body>
                                {isMdOrLarger && themeMode.theme == "light" &&
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    width: '50px',  // Adjust width as needed
                                    height: '100%',
                                    
                                    pointerEvents: 'none'  // Allow the scrollable content to be interacted with
                                }}></div>}</>}
                    </StyledCard>
                    {featuredPools.length == 0 ? <></> : 
                    <>
                    <div className="mt-3">
                        <StyledH5><strong>Featured Pools</strong></StyledH5>
                    </div>
                    <StyledCard style={{ position: 'relative' }}>
                            <>
                                <StyledCard.Body className='d-flex' style={{
                                    overflowX: 'auto',
                                    whiteSpace: 'nowrap',
                                    gap: '1rem',
                                    paddingRight: '1.5rem',
                                    minWidth: 0,
                                    width: '100%'  // Ensure there's space on the right for the gradient
                                }}>

                                    {featuredPools.map((pool) => (
                                        <div className="mx-1 my-1">

                                            <PoolPreviewCard data={pool} />
                                        </div>
                                    ))}
                                </StyledCard.Body>
                                {isMdOrLarger && themeMode.theme == "light" &&
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    width: '50px',  // Adjust width as needed
                                    height: '100%',
                                    background: 'linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0))',
                                    pointerEvents: 'none'  // Allow the scrollable content to be interacted with
                                }}></div>}</>
                    </StyledCard></>}
                    </> : <></>}

                </div>

            </div>

    )

}



export default MyPools;