import React from 'react';
import { Avatar as DefaultAvatar } from 'stream-chat-react';
import { useChannelPreviewInfo } from 'stream-chat-react';
import { useChannelStateContext } from 'stream-chat-react';
import { useChatContext } from 'stream-chat-react';
import { useTranslationContext } from 'stream-chat-react';
import { faClose, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const DefaultMenuIcon = ({ title }) => {
  const { t } = useTranslationContext('MenuIcon');

  return (
    <svg data-testid='menu-icon' viewBox='0 0 448 512' xmlns='http://www.w3.org/2000/svg'>
      <title>{title ?? t('Menu')}</title>
      <path
        d='M0 88C0 74.75 10.75 64 24 64H424C437.3 64 448 74.75 448 88C448 101.3 437.3 112 424 112H24C10.75 112 0 101.3 0 88zM0 248C0 234.7 10.75 224 24 224H424C437.3 224 448 234.7 448 248C448 261.3 437.3 272 424 272H24C10.75 272 0 261.3 0 248zM424 432H24C10.75 432 0 421.3 0 408C0 394.7 10.75 384 24 384H424C437.3 384 448 394.7 448 408C448 421.3 437.3 432 424 432z'
        fill='currentColor'
      />
    </svg>
  );
};

const UnMemoizedChannelHeader = ({ setChatVisible, backToChannelList, ...props }) => {
  const {
    Avatar = DefaultAvatar,
    MenuIcon = DefaultMenuIcon,
    image: overrideImage,
    live,
    title: overrideTitle,
  } = props;

  const themeMode = useTheme();
  const { channel, watcher_count } = useChannelStateContext('ChannelHeader');
  const { openMobileNav } = useChatContext('ChannelHeader');
  const { t } = useTranslationContext('ChannelHeader');
  const { displayImage, displayTitle } = useChannelPreviewInfo({
    channel,
    overrideImage,
    overrideTitle,
  });

  const { member_count, subtitle } = channel?.data || {};
  const userSlug = channel?.state?.members?.[0]?.user?.id || 'default-slug'; // Assuming there's at least one member and the slug is their user id.

  return (
    <div className='str-chat__header-livestream str-chat__channel-header'>
      <button
        aria-label={t('aria/Menu')}
        className='str-chat__header-hamburger'
        onClick={openMobileNav}
      >
        <MenuIcon />
      </button>
      <button
        aria-label={t('aria/Back')}
        className='str-chat__header-back'
        onClick={backToChannelList}
        style={{ background: 'none', border: 'none', cursor: 'pointer', marginRight: '10px' }}
      >
        <FontAwesomeIcon icon={faArrowLeft} size="lg" color={themeMode.onSurface} />
      </button>
      <Link to={`/dashboard/user/${userSlug}`}>
        <Avatar
          image={displayImage}
          name={displayTitle}
          shape='rounded'
          size={channel?.type === 'commerce' ? 60 : 40}
        />
      </Link>
      <div className='str-chat__header-livestream-left str-chat__channel-header-end'>
        <p className='str-chat__header-livestream-left--title str-chat__channel-header-title'>
          <Link to={`/dashboard/user/${userSlug}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            {displayTitle}
          </Link>{' '}
          {live && (
            <span className='str-chat__header-livestream-left--livelabel'>{t('live')}</span>
          )}
        </p>
        {subtitle && <p className='str-chat__header-livestream-left--subtitle'>{subtitle}</p>}
        <p className='str-chat__header-livestream-left--members str-chat__channel-header-info'>
          {!live && !!member_count && member_count > 0 && (
            <>
              {t('{{ memberCount }} members', {
                memberCount: member_count,
              })}
              ,{' '}
            </>
          )}
          {t('{{ watcherCount }} online', { watcherCount: watcher_count })}
        </p>
      </div>
      <div>
        <CloseIcon onClick={() => setChatVisible(false)} icon={faClose} fontSize={'1.5em'} color={themeMode.onSurface} />
      </div>
    </div>
  );
};

/**
 * The ChannelHeader component renders some basic information about a Channel.
 */
const CloseIcon = styled(FontAwesomeIcon)`
  transition: transform 0.2s ease, color 0.2s ease;
  &:hover {
    transform: scale(1.2);
  }
`;

export const CustomChannelHeader = React.memo(UnMemoizedChannelHeader);
