
import EmailVerificationSent from './EmailVerificationSent';
import { StyledParagraph } from './StyledBootstrap';

function ContractNotLive() {


    return (
        
        
        <EmailVerificationSent><StyledParagraph className='mt-3'>You are now in the signing period! The Success Pooling Agreement for your pool will be live in a few hours. Stay tuned!</StyledParagraph></ EmailVerificationSent>
        );
}

export default ContractNotLive;


