import { StyledModal, StyledButton, StyledParagraph } from "./StyledBootstrap";
import { useAuth } from "./AuthContext";

function TutorialModal({ show, tutorialPart, onNext, onSkip, onBack }) {
  const { userFirstName } = useAuth();

  const renderContent = () => {
    switch (tutorialPart) {
      case 1:
        return (
          <>
            <StyledParagraph>
              We’re currently invite-only, so you’ve just joined a <strong>select community of talented students</strong> from universities across the United States.
            </StyledParagraph>
            <StyledParagraph>
              Please take just <strong>two minutes</strong> to read through this tutorial. We promise—it’s worth it.
            </StyledParagraph>
          </>
        );
      case 2:
        return (
          <>
            <StyledParagraph>
              College is one of the rare times in life when you’re surrounded by a uniquely <strong>diverse, talented network</strong> with <strong>immense future potential</strong>.
            </StyledParagraph>
            <StyledParagraph>
              Your roommate could become a famous actor. That girl from your student org might build the next Microsoft.
            </StyledParagraph>
            <StyledParagraph>
              Yet, most of us barely tap into this opportunity—joining a few clubs, attending alumni events, or occasionally asking for advice.
            </StyledParagraph>
            <StyledParagraph>
              We offer a way for you to <strong>unlock the full value of your college connections</strong>—through <strong>success pools</strong>.
            </StyledParagraph>
            <StyledParagraph>
              Success pools give you a <strong>direct share</strong> in the <strong>future earnings</strong> of your <strong>college network</strong>.
            </StyledParagraph>
          </>
        );
      case 3:
        return (
          <>
            <StyledParagraph>
              A success pool is a <strong>group of students</strong> who agree to exchange a <strong>small percentage of their future earnings</strong> for a set period of time after graduation. The percentage, time frame, and participants are entirely up to you.
            </StyledParagraph>
            <StyledParagraph>
              Once you join a pool, <strong>Prospinity handles everything</strong>—including the exchange—year after year.
            </StyledParagraph>
            <StyledParagraph>
              Each member of your pool is <strong>invested in your success</strong>. They will share networks, resources, and opportunities to help you grow. And if one person in your pool hits it big, your investment pays off.
            </StyledParagraph>
          </>
        );
      case 4:
        return (
          <>
            <StyledParagraph>
              Students can be part of <strong>multiple pools</strong> (in fact, we recommend it!). Here’s how you can join one:
            </StyledParagraph>
            <StyledParagraph>
              <strong><u>1. Create your own</u></strong>: Click <i>Create Pool</i> and invite people you know. Even if they’re not on the platform yet, enter their university emails, and we’ll send them an invite.
            </StyledParagraph>
            <StyledParagraph>
              <strong><u>2. Get matched</u></strong>: Sit back and relax while we invite you to a pool we think fits you. This may take a few weeks, but we’ll notify you by email and text once your pool is ready. You’ll have the option to join or not, and we may even match you with students from other schools.
            </StyledParagraph>
            <StyledParagraph>
              <strong><u>3. Through your student organization</u></strong>: If you’re part of a student org, check with your leader to see if we have a partnership with them. If not, and you’d like to set up a partnership or organizational pool, email us at <a href="mailto:partnerships@prospinity.com">partnerships@prospinity.com</a>.
            </StyledParagraph>
          </>
        );
      case 5:
        return (
          <>
            <StyledParagraph>
              While you’re waiting to be matched, <strong>explore the Prospinity network</strong>.
            </StyledParagraph>
            <StyledParagraph>
              See someone interesting? Send them a <strong>portfolio request!</strong> You’ll be connected for a brief 15-minute chat to get to know them better. Afterward, they’ll be added to your <strong>Portfolio</strong>—a set of connections far more meaningful than LinkedIn because you’ve actually met and will continue to meet regularly to help each other grow.
            </StyledParagraph>
            <StyledParagraph>
              <strong>Portfolio connections</strong> can even lead to <strong>future success pools</strong>.
            </StyledParagraph>
            <StyledParagraph>
              Don’t hesitate to send requests—our platform is built around meeting new people and embracing serendipity. Students are here to connect with others they haven’t met before.
            </StyledParagraph>
            <StyledParagraph>
              We recommend starting by sending <strong>three meeting requests</strong> to people you don’t know in your <i>Daily Picks</i> section—ideally from <strong>different schools</strong>.
            </StyledParagraph>
          </>
        );
      case 6:
        return (
          <>
            <StyledParagraph>
              As an invite-only platform, each member has a <strong>limited number of invites</strong>. Feel free to invite friends and acquaintances who would be a great fit for Prospinity.
            </StyledParagraph>
            <StyledParagraph>
              If you have questions, check out our <strong>FAQs</strong>.
            </StyledParagraph>
            <StyledParagraph>
              Let's get started...
            </StyledParagraph>
          </>
        );
      default:
        return <StyledParagraph>Unknown part!</StyledParagraph>;
    }
  };



  const renderTitle = () => {
    switch (tutorialPart) {
      case 1:
        return (
          <>
            Hey, {userFirstName}!
          </>
        );
      case 2:
        return (
          <>
            Why Prospinity?
          </>
        );
      case 3:
        return (
          <>
            What is a Success Pool?
          </>
        );
      case 4:
        return (
          <>
            How Do I Join a Pool?
          </>
        );
      case 5:
        return (
          <>
            Portfolio
          </>
        );
      case 6:
        return (
          <>
            Finally
          </>
        );
      default:
        return <StyledParagraph>Unknown part!</StyledParagraph>;
    }
  };

  return (
    <StyledModal show={show} onHide={onSkip} centered>
      <StyledModal.Header>
        <StyledModal.Title>{renderTitle()}</StyledModal.Title>
      </StyledModal.Header>
      <StyledModal.Body>{renderContent()}</StyledModal.Body>
      <StyledModal.Footer>
        {tutorialPart > 1 ? (
          <StyledButton variant="secondary" onClick={onBack}>
            Back
          </StyledButton>
        ) : null}
        <StyledButton variant="primary" onClick={onNext}>
          {tutorialPart < 6 ? "Next" : "Begin"}
        </StyledButton>
      </StyledModal.Footer>
    </StyledModal>
  );
}

export default TutorialModal;