import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import TermsofUse from './TermsOfUse';
import PrivacyPolicy from './PrivacyPolicy'
import SignIn from './UpdatedSignIn';
import SignUp from './SignUp';
import Dashboard from './Dashboard';
import Home from './Home';
import Payments from './Payments';
import NewCreatePool from './NewCreatePool';
import UserProfile from './UserProfile';
import EmailVerification from './EmailVerification';
import PasswordReset from './PasswordReset';
import PasswordResetEmail from './PasswordResetEmail';
import Settings from './Settings';
import NotFoundPage from './NotFoundPage';
import { AuthProvider, useAuth } from './AuthContext';
import { ProtectedRoute } from './ProtectedRoute';
import PoolPage from './PoolPage';
//import ErrorTestComponent from './ErrorTestComponent';
//import EmailVerificationSent from './EmailVerificationSent';
import { ErrorBoundary } from 'react-error-boundary';
import MainLoading from './MainLoading';
import { ThemeProvider } from './ThemeContext';
import { StyledButton, StyledH4 } from './StyledBootstrap';
import { useTheme } from 'styled-components';
//import TestSignPage from './TestSignPage';
import SigningCongratulations from './SigningCongratulations';
import ContractNotLive from './ContractNotLive';
import './axiosConfig';
import ConfidentialityAndIPAcknowledgment from './ConfidentialityAndIPAcknowledgment';
import FAQs from './FAQs';
import TestVideoCall from './TestVideoCall';
import track from 'react-tracking';
import { myAxiosInstance } from './axiosConfig';
import { useTracking } from 'react-tracking';
import { Mixpanel } from './Mixpanel';
import FinishRegistration from './UpdatedFinishRegistration'; 
import MeetingPage from './MeetingPage';
import PortfolioPage from './PortfolioPage';
import MyPools from './MyPools';
import ChatPageMobile from './ChatPageMobile';
import NotificationsPageMobile from './NotificationsPageMobile';
import StreamLinedCreatePool from './StreamLinedCreatePool';
import MemberCodeOfConduct from './MemberCodeofConduct';
//import SurveyPage from './SurveyPage';
import BonusRedirect from './BonusPage'

function ErrorFallback({ error, resetErrorBoundary }) {
    const themeMode = useTheme();

    const errorLogout = () => {

        localStorage.removeItem('userToken');
        window.location.href = '/signin';


    }
    return (
        <div className='d-flex flex-column justify-content-center align-items-center text-center vh-100 w-100' style={{ backgroundColor: themeMode.background }}>
            <StyledH4>Whoops! Something went wrong.
            </StyledH4>
            <StyledButton onClick={resetErrorBoundary} className='my-3'>Go Back to Your Dashboard</StyledButton>
            <StyledButton onClick={errorLogout}>Logout</StyledButton>
        </div>
    );
}




function App() {



    const dispatchTrackingEvent = data => {
        console.log("im tracking");
        console.log("tracking data", data);
        const { event, ...dataWithoutEvent } = data;
        Mixpanel.track(event, dataWithoutEvent);
        /*myAxiosInstance.post('/api/track-frontend/', {"email": "", "content": JSON.stringify(data)})
          .then(response => {
            console.log('Tracking data sent successfully:', response.data);
          })
          .catch(error => {
            console.error('Error sending tracking data:', error);
          });*/
      };

    const { Track } = useTracking(
        {},
        { dispatch: data => dispatchTrackingEvent(data) }
      );


    return (
        <Track>
            <ThemeProvider>
                <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onReset={() => {
                        // Logic to reset the app's state
                        window.location.href = '/dashboard';
                    }}
                >

                    <AuthProvider>
                        
                        <RouterWithRoutes />
                        
                    </AuthProvider>

                </ErrorBoundary>
            </ThemeProvider>
        </Track>
    );
}


function RouterWithRoutes() {
    const { isLoading } = useAuth();

    if (isLoading) {
        return <MainLoading />;
    }

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/signin" element={<ProtectedRoute requiredStage={0} signin={true}><SignIn /></ProtectedRoute>} />
                <Route path="/signup" element={<ProtectedRoute requiredStage={0} signin={true}><SignUp /></ProtectedRoute>} />
                {/*<Route path="/signup/:key" element={<ProtectedRoute requiredStage={0} signin={true}><SignUp /></ProtectedRoute>} />*/}
                <Route path="/password-reset/confirm/:slug/:verificationKey" element={<ProtectedRoute requiredStage={0} signin={true}><PasswordReset /></ProtectedRoute>} />
                <Route path="/password-reset-email" element={<ProtectedRoute requiredStage={0} signin={true}><PasswordResetEmail /></ProtectedRoute>} />
                <Route path="/verify/:verificationKey" element={<EmailVerification />} />
                <Route path="/home" element={<Navigate to="/dashboard" />} />
                <Route path="/signing-out" element={<MainLoading />} />
                <Route path="/dashboard" element={
                    <ProtectedRoute requiredStage={3}>
                        <Dashboard />
                    </ProtectedRoute>
                }>
                    <Route index element={<Home/>} />
                    <Route path="home" element={<Home />} />
                    <Route path="payments" element={<Payments />} />
                    <Route path="user/:slug" element={<UserProfile />} />
                    <Route path="pool/:slug" element={<PoolPage />} />
                    <Route path="meeting/:slug" element={<MeetingPage />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="create-pool" element={<StreamLinedCreatePool />} />
                    <Route path="faqs" element={<FAQs />} />
                    <Route path="portfolio" element={<PortfolioPage />} />
                    <Route path="pools" element={<MyPools />} />
                    <Route path="chat-page" element={<ChatPageMobile />} />
                    <Route path="notifications-page" element={<NotificationsPageMobile />} />
                    <Route path="member-code-of-conduct" element={<MemberCodeOfConduct />} />
                    
                    
                    
                   
                </Route>
                <Route path="/finish-registration" element={
                    <ProtectedRoute requiredStage={1}>
                        <FinishRegistration />
                    </ProtectedRoute>
                } />
                
                {/*<Route path="test-call" element={<TestVideoCall />} />*/}
                {/* <Route path="/tutorial/:club_name" element={<SurveyPage />} /> */}
                <Route path="/bonus" element={<BonusRedirect />} />

                <Route path="/termsofuse" element={<TermsofUse />} />
                
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/signed-success-pool" element={<SigningCongratulations />} />
                <Route path="/contract-not-live" element={<ContractNotLive />} />
                <Route path="/agreement-confidentiality" element={<ConfidentialityAndIPAcknowledgment />} />
                <Route path="*" element={<NotFoundPage />} />

            </Routes>
            </Router>
        
    );
}


export default App;


