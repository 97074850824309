import { createGlobalStyle } from 'styled-components';

const GlobalJoyrideStyles = createGlobalStyle`


  .react-joyride__spotlight {
    box-shadow: 0 0 15px 7px ${({ theme }) => theme.highlight} !important;
    border-radius: 20px !important;
  }
`;

export default GlobalJoyrideStyles;