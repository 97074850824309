import { Nav, Form, FormControl, Row, Col, Container, InputGroup, Dropdown, Card, Stack, Button, FormGroup, FormLabel } from 'react-bootstrap';
import React, { useEffect, useState, useRef } from 'react';
import { apiP } from './CriticalFunctions';
import { FormInput } from './FormComponents';
import { useNavigate } from 'react-router-dom';
import MultiSelectUsers from './MultiSelectUsers';
import MultiSelectEmails from './MultiSelectEmails';
import { convertUTCtoLocalDateTime } from './CriticalFunctions';
import "react-datepicker/dist/react-datepicker.css";
import { StyledParagraph, StyledCard, StyledForm, StyledFormCheck, StyledFormControl, StyledFormLabel, StyledFormSelect, StyledFormGroup, StyledButton, StyledModal, StyledH4 } from './StyledBootstrap';
import { useAuth } from './AuthContext';
import { myAxiosInstance } from './axiosConfig';
import AddUserToPool from './AddUserToPool';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { StyledH2 } from './StyledBootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import Loading from './Loading';


function StreamLinedCreatePool() {
    const [message, setMessage] = useState('');
    //const [error, setError] = useState({ hasError: false, message: '', step: 1 });


    const { userFirstName } = useAuth();

    const themeMode = useTheme();
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });
    const bottomRef = useRef(null);

    const [error, setError] = useState(false);

    const packages = [
        {
            name: "Alpha",
            description: "Balanced (most popular)",
            contribution: "1%",
            timeHorizon: "10 years"
        },
        {
            name: "Gamma",
            description: "Community focused",
            contribution: "2%",
            timeHorizon: "5 years"
        },
        {
            name: "Delta",
            description: "Real diversification",
            contribution: "5%",
            timeHorizon: "15 years"
        }
    ];


    const starNames = [
        "Andromeda", "Perseus", "Orion", "Callisto", "Athena", "Apollo", "Selene", "Helios",
        "Elektra", "Zephyros", "Pandora", "Hermes", "Nyx", "Asteria", "Eos", "Hyperion",
        "Phoebe", "Dione", "Pallas", "Artemis", "Astraeus", "Gaia", "Icarus", "Theseus",
        "Achilles", "Hestia", "Hercules", "Cronus", "Triton", "Hesperos", "Aegis", "Euryale",
        "Atlas", "Boreas", "Orpheus", "Calypsa", "Clytemnestra", "Leda", "Ariadne", "Thalassa",
        "Chiron", "Nemesis", "Pegasus", "Castor", "Pollux", "Erebus", "Eudora", "Phaedra",
        "Hades", "Iris", "Helene", "Oceanus", "Theia", "Psyche", "Melpomene", "Circe",
        "Medusa", "Typhon", "Pleiades", "Daphne", "Selene", "Deimos", "Phobos", "Eros",
        "Hypnos", "Thanatos", "Kronos", "Prometheus", "Adonis", "Persephone", "Dionysus"
    ];


    const [poolName, setPoolName] = useState("");
    const [poolPackage, setPoolPackage] = useState(0);
    const poolMembers = useRef([{
        company: '',
        email: '',
        first_name: '',
        graduation_date: '',
        is_verified: '',
        last_name: '',
        major_or_grad_program: '',
        pooled: '',
        portfolio_count: '',
        profile_picture: '',
        slug: '',
        university: ''}]);
    const [triggerRender, setTriggerRender] = useState(0);
    /*const subComponentRefs = useRef([]);
    const addSubComponentRef = (ref) => {
        if (ref && !subComponentRefs.current.includes(ref)) {
            subComponentRefs.current.push(ref);
        }
    };*/


    /*const changePoolMembersLength = (len) => {
        if (poolMembers.current.length < len) {
            const newMembers = [...poolMembers.current];
            for (let i = 0; i < (len - poolMembers.current.length); i++) {
                newMembers.push({});
            }
            poolMembers.current = newMembers;
            setTriggerRender(prev => prev + 1); // Force re-render
        } else if (poolMembers.current.length > len) {
            poolMembers.current = poolMembers.current.slice(0, len);
            setTriggerRender(prev => prev + 1); // Force re-render
        }
    };*/



    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [poolMembers]); // Scrolls to the bottom each time an invite is added


    useEffect(() => {
        console.log(poolMembers);
    }, [poolMembers]); // Scrolls to the bottom each time an invite is added

    const handleAddInvite = () => {
        poolMembers.current.push({
            company: '',
            email: '',
            first_name: '',
            graduation_date: '',
            is_verified: '',
            last_name: '',
            major_or_grad_program: '',
            pooled: '',
            portfolio_count: '',
            profile_picture: '',
            slug: '',
            university: ''
        });
        setTriggerRender(prev => prev + 1); // Force re-render
        //addSubComponentRef();
    }



    const handleDeleteUser = (index) => {
        poolMembers.current = poolMembers.current.filter((_, i) => i !== index);
        //subComponentRefs.current = subComponentRefs.current.filter((_, i) => i !== index);
        setTriggerRender(prev => prev + 1); // Force re-render
    };




    const editPoolMember = (selected, index) => {
        poolMembers.current[index] = {...poolMembers.current[index], ...selected}; // Directly update ref without triggering re-render
    };

    const getDefaultDeadline = (daysToAdd = 7) => {
        const now = new Date();
        now.setDate(now.getDate() + daysToAdd); // Add default days to current date
        return now.toISOString().slice(0, 16); // Convert to 'YYYY-MM-DDTHH:mm' format
    };

    const { userSlug } = useAuth();

    /*useEffect(() => {

        setError({ hasError: true, message: message, step: 1 })


    }, [message, setMessage]);*/

    useEffect(() => {

        myAxiosInstance.post('/api/track/', { user_id: userSlug.split('-')[2], event_name: "Create Pool Click", event_data: {} })
            .then((res) => console.log('Initial tracking sent'))
            .catch((err) => console.log('Initial tracking failed', err));


    }, []);



    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0);
    



    const [isBottomLoading, setIsBottomLoading] = useState(false);














    const goToNextStep = () => {
        if (currentStep == 3)
        {
            handleInfoSubmit();
        }
        else{
            setCurrentStep(prev => prev + 1);

        }
        
        
    };

    // Function to navigate to the previous form step
    const goToPreviousStep = () => {
        setCurrentStep(prev => prev - 1);
    };





    const prepareData = () => {
        let data = {
            name: poolName === "" ? userFirstName + "'s Pool" : poolName,
            description: '',
            threshold: '0',
            contribution_percent: poolPackage == 0 ? 1 : poolPackage == 1 ? 2 : 5,
            time_horizon: poolPackage == 0 ? 10 : poolPackage == 1 ? 5 : 15,
            invitees: poolMembers.current.map((member, index) => ({email: member.email, full_name: member.first_name + " " + member.last_name})),
            invitation_deadline: getDefaultDeadline(8),
            sign_deadline: getDefaultDeadline(8),
            percent_signitures_to_activate: 50,
            minimum_income: 0
        }


        //data.invitation_deadline = data.sign_deadline;

        return data;
    };


    const handleInfoSubmit = async () => {
        const data = prepareData();
        console.log("creating pool data...");
        console.log(data);
        setIsBottomLoading(true);

        try {
            const response  = await myAxiosInstance.post('/api/create-pools/', data);
            navigate('/dashboard/home');
        }
        catch (error) 
        {
            console.log(error);
            setError(true);
        }

        /*await apiP(
            data,
            'create-pools/', // Make sure this endpoint is correct
            setMessage,
            'Pool Created! Taking you to your pools...',
            navigate,
            '/dashboard/home', // Update this navigation path as needed
            null, // Update or remove these parameters as needed
            null,
            null,
            'post'
        );*/
        setIsBottomLoading(false);
    };


    /*const gatherDataFromSubComponents = () => {
        const allData = subComponentRefs.current.map(ref => ref?.getData());
        console.log("Data from all AddUserToPool components:", allData);
    };*/


    return (
        <Row className='justify-content-center'>
            <Col className='col-lg-8 col-md-10'>
                <StyledCard>
                    <StyledCard.Body>
                        {currentStep === 0 && (
                            <>

                                <StyledH4 className='mb-2'><strong>Create your own success pool</strong></StyledH4>
                                <StyledParagraph>
                                    It takes just a few minutes. As the pool captain, you have the privilege of choosing the terms and members to invite.
                                </StyledParagraph>
                                <StyledParagraph>
                                    You should have <i>at least one member in mind</i> to invite and create the initial agreement (after that, if you would like, existing members can vote other people in).
                                </StyledParagraph>
                                <StyledParagraph>
                                    Members don’t already have to be on the Prospinity platform to be invited to a pool! We can send an invite to their university email address so they can create an account.
                                </StyledParagraph>
                            </>
                        )}

                        {currentStep === 1 && (
                            <>
                                <StyledH4 className='mb-3'><strong>What should we call it?</strong></StyledH4>

                                <StyledFormGroup className="mb-3">
                                    <InputGroup>
                                        <StyledFormControl
                                            value={poolName}
                                            onChange={(e) => setPoolName(e.target.value)}
                                            placeholder='Enter your pool name'
                                        />
                                    </InputGroup>
                                </StyledFormGroup>

                                <StyledParagraph className='ml-2' style={{ fontSize: '0.7rem' }}>
                                    Can't think of a name? <a
                                        onClick={() => {
                                            const randomStarName = starNames[Math.floor(Math.random() * starNames.length)];
                                            setPoolName(randomStarName);
                                        }}
                                        style={{ cursor: 'pointer', color: themeMode.primary, textDecoration: 'underline' }}
                                    >
                                        Generate one
                                    </a>
                                </StyledParagraph>

                            </>
                        )}
                        {currentStep === 2 && (

                            <>
                                <StyledH4 className='mb-3'><strong>Select a pool type...</strong></StyledH4>
                                {!isMdOrLarger &&
                                    packages.map((pkg, index) => (
                                        <StyledCard
                                            key={index}
                                            className='my-2'
                                            onClick={() => setPoolPackage(index)}
                                            style={{
                                                width: '100%',
                                                boxShadow: poolPackage === index ? '0 6px 12px rgba(0, 0, 0, 0.2)' : '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                transition: 'transform 0.3s ease, background-color 0.3s ease',
                                                backgroundColor: poolPackage === index ? themeMode.primary : themeMode.surface,
                                                color: poolPackage === index ? themeMode.onPrimary : themeMode.onSurface,
                                                transform: poolPackage === index ? 'scale(1.04)' : 'scale(1)',
                                                cursor: 'pointer', // Added to make it clear that the card is clickable
                                                border: poolPackage === index ? `2px solid ${themeMode.primaryVariant}` : '2px solid transparent'
                                            }}
                                            onMouseEnter={(e) => {
                                                e.currentTarget.style.transform = 'scale(1.04)'; // Hover effect to make it more clickable
                                            }}
                                            onMouseLeave={(e) => {
                                                e.currentTarget.style.transform = poolPackage === index ? 'scale(1.04)' : 'scale(1)';
                                            }}
                                        >
                                            <StyledCard.Body>
                                                <StyledCard.Title><strong>{pkg.name}</strong></StyledCard.Title>
                                                <StyledCard.Text>
                                                    {pkg.description}<br />
                                                    <strong>{pkg.contribution}</strong><br />
                                                    <strong>{pkg.timeHorizon}</strong>
                                                </StyledCard.Text>
                                            </StyledCard.Body>
                                        </StyledCard>
                                    ))
                                }
                                {isMdOrLarger &&
                                    <Row className="justify-content-center mb-3">
                                        {packages.map((pkg, index) => (
                                            <Col md={4} key={index} className="d-flex">
                                                <StyledCard
                                                    onClick={() => setPoolPackage(index)}
                                                    style={{
                                                        width: '400px',
                                                        boxShadow: poolPackage === index ? '0 6px 12px rgba(0, 0, 0, 0.2)' : '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                        transition: 'transform 0.3s ease, background-color 0.3s ease',
                                                        backgroundColor: poolPackage === index ? themeMode.primary : themeMode.surface,
                                                        color: poolPackage === index ? themeMode.onPrimary : themeMode.onSurface,
                                                        transform: poolPackage === index ? 'scale(1.04)' : 'scale(1)',
                                                        cursor: 'pointer', // Added to make it clear that the card is clickable
                                                        border: poolPackage === index ? `2px solid ${themeMode.primaryVariant}` : '2px solid transparent'
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        e.currentTarget.style.transform = 'scale(1.04)'; // Hover effect to make it more clickable
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.currentTarget.style.transform = poolPackage === index ? 'scale(1.04)' : 'scale(1)';
                                                    }}
                                                >
                                                    <StyledCard.Body>
                                                        <StyledCard.Title><strong>{pkg.name}</strong></StyledCard.Title>
                                                        <StyledCard.Text>
                                                            {pkg.description}<br />
                                                            <strong>{pkg.contribution}</strong><br />
                                                            <strong>{pkg.timeHorizon}</strong>
                                                        </StyledCard.Text>
                                                    </StyledCard.Body>
                                                </StyledCard>
                                            </Col>
                                        ))}
                                    </Row>
                                }
                            </>

                        )}

                        {/*currentStep === 3 && (
                            <>
                                <StyledH4 className='mb-3'><strong>Approximately, how many people would you initially like to invite?</strong></StyledH4>
                                <StyledFormGroup className="mb-3">
                                    <InputGroup>
                                        <StyledFormControl
                                            type="number"
                                            value={poolMembers.length === 0 ? '' : poolMembers.length} // Allow empty input
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (value === '' || /^\d+$/.test(value)) { // Allow empty or numeric input only
                                                    changePoolMembersLength(value === '' ? 0 : parseInt(value, 10));
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                </StyledFormGroup>
                            </>
                        )*/}


                        {currentStep == 3 &&
                            <>
                                <StyledH4 className='mb-3'><strong>Fill in members below...</strong></StyledH4>
                                <StyledParagraph>If you can't find someone, go ahead and type in their full name and university email, and we will send them an invite to the platform and the pool.</StyledParagraph>
                                <div className='px-2' style={{ height: "50vh", overflowY: 'auto' }}>
                                {poolMembers.current.map((member, i) => (
                                        <AddUserToPool
                                            key={member.email ? member.email : `pool-member-${i}`} //// Unique key
                                            otherUsers={[]}
                                            value={member}
                                            onChange={(selected) => {
                                                editPoolMember(selected, i);
                                            }}
                                            label={i + 1}
                                            endpoint={true}
                                            deleteUser={() => { handleDeleteUser(i) }}
                                        />
                                    ))}
                                    {/* Bottom marker for scrolling */}
                                    <div className='w-100 d-flex justify-content-center mb-2'>
                                        <StyledButton style={{fontSize: '0.8rem'}} onClick={handleAddInvite}>
                                            <FontAwesomeIcon icon={faPlus} /> Add Member
                                        </StyledButton>
                                    </div>

                                    <div ref={bottomRef} />
                                </div>


                                {/*<MultiSelectEmails
                                    value={inviteEmails}
                                    label="Emails"
                                    onChange={(selected) => { setInviteEmails(selected) }}
                                />*/}


                                {/*<StyledParagraph style={{ fontSize: '0.5rem' }}>
                                    <>
                                        By clicking "Create Pool" and viewing certain sections of our platform, you agree to keep all content,
                                        including the Success Pooling Agreement, confidential and acknowledge that it is the
                                        intellectual property of Prospinity. Creating this pool does not constitute a binding legal
                                        commitment to any terms other than the confidentiality and intellectual property
                                        acknowledgment itself. For full details, see our <a href="/agreement-confidentiality">Confidentiality and Intellectual Property Agreement</a>.
                                    </>
                                </StyledParagraph>*/}

                            </>
                        }

                        {currentStep != 4 ?
                            <Row className='d-flex justify-content-center mt-1'>
                                <div>
                                {error && currentStep == 3 ? <StyledParagraph className='mb-2 text-center' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please make sure you have entered all of the pool information correctly.</StyledParagraph> : <></>}
                                </div>
                                <Col md={8} className='d-flex justify-content-center'>
                                    {currentStep == 0 ?
                                        <StyledButton onClick={goToNextStep} className='w-100'>
                                            Get Started
                                        </StyledButton>
                                        : <></>}
                                    {currentStep != 0 ?
                                        <>
                                            <StyledButton onClick={goToPreviousStep} className='mr-2 w-50' style={{ backgroundColor: themeMode.background, color: themeMode.onBackground }}>
                                                Back
                                            </StyledButton>
                                            <StyledButton onClick={goToNextStep} className='ml-2 w-50'>
                                                {currentStep == 3 ? isBottomLoading ? <Loading /> : <>Send Invites</> : <>Next</>}
                                            </StyledButton>
                                        </>
                                        : <></>}

                                </Col>

                            </Row> : <></>}
                        
                        {currentStep == 4 &&
                            <>
                                <StyledParagraph className='mt-2' style={{ fontSize: '0.7rem' }}>
                                    *If you are a student org leader, please email <a
                                        href="mailto:partnerships@prospinity.com"
                                        style={{ color: themeMode.primary, textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        partnerships@prospinity.com
                                    </a> if you have any questions about your partnership.
                                </StyledParagraph>
                                <StyledParagraph style={{ fontSize: '0.7rem' }}>
                                    *Want more customization? Send an email to <a
                                        href="mailto:pools@prospinity.com"
                                        style={{ color: themeMode.primary, textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        pools@prospinity.com
                                    </a> with your request, and we are happy to set up a call and a custom solution for you.
                                </StyledParagraph></>}






                        {/*<StyledModal show={error.hasError && error.message !== ''} centered>
                            <StyledModal.Header>{isBottomLoading ? <>Creating Pool...</> : <>Pool Creation Failed</>}</StyledModal.Header>


                            <StyledModal.Body>{error.message}</StyledModal.Body>
                            <StyledModal.Footer>
                                {isBottomLoading ? <></> : <StyledButton onClick={() => {

                                    setCurrentStep(error.step);
                                    setError({ hasError: false, message: '', step: 1 })
                                }}>Fix</StyledButton>}

                            </StyledModal.Footer>

                        </StyledModal>*/}

                    </StyledCard.Body>
                </StyledCard>
            </Col>
        </Row >

    );
}



export default StreamLinedCreatePool;