import { Modal, Button } from 'react-bootstrap';
import { StyledModal, StyledButton } from './StyledBootstrap';



function CustomModal({ children, title, actionText, actionFunction, show, setShow, message, setMessage, handleClose, showCloseButton=true, showActionButton=true }) {

    const handleButton = () => {
        actionFunction();
    }
    return (
    <StyledModal show={show} onHide={handleClose} backdrop="static"
        keyboard={false} centered>
        <StyledModal.Header closeButton>
            <StyledModal.Title>{title}</StyledModal.Title>
        </StyledModal.Header>
        <StyledModal.Body>
            {children}
            <div className='mt-3'>
            {message}
            </div>
        </StyledModal.Body>
        <StyledModal.Footer>
            <div>
            
            {showCloseButton && (
                <StyledButton variant="secondary" onClick={handleClose} className={showActionButton ? 'mx-3' : ''}>
                    Close
                </StyledButton>
            )}
            {showActionButton && (
                <StyledButton variant="primary" onClick={handleButton}>
                    {actionText}
                </StyledButton>
            )}
            
            
            </div>
            
        </StyledModal.Footer>
    </StyledModal>);
}

export default CustomModal;