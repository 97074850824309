import { StyledModal, StyledButton, StyledParagraph } from "./StyledBootstrap";
import { myAxiosInstance } from './axiosConfig';
import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";


function DeleteMeetingModal({ show, setShow, slug, name, setBottomReload, bottomReload }) {

    const themeMode = useTheme();
    const navigate = useNavigate();


    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);

    const handleDeleteMeetingButtonClick = async () => {

        try {
            setDeleteButtonLoading(true);
            const response = await myAxiosInstance.delete('/api/manage-meeting/' + slug + "/");
            if (bottomReload)
            {
                setBottomReload(bottomReload + 1);
            }
            else{
                navigate("/dashboard");

            }
            

        }
        catch (error) {
            console.error('Error deleting meeting', error.response.data);
        }
        finally {
            setDeleteButtonLoading(false);
        }



    }





    return (
        <StyledModal show={show} onHide={() => { setShow(false) }} centered>
            <StyledModal.Header>
                <StyledModal.Title>Don't want to meet {name}?</StyledModal.Title>
            </StyledModal.Header>
            <StyledModal.Body>We recommend you embrace serendipity - you never know how someone can help you down the line. If you really don't want to meet, click below, and we'll delete the request (we won't send them a message or alert them that you did this).</StyledModal.Body>
            <StyledModal.Footer>
                <StyledButton style={{ backgroundColor: themeMode.background, color: themeMode.onBackground }} onClick={() => { setShow(false) }}>Back</StyledButton>
                <StyledButton variant="primary" onClick={handleDeleteMeetingButtonClick}>
                    Delete
                </StyledButton>
            </StyledModal.Footer>
        </StyledModal>
    );
};

export default DeleteMeetingModal;


