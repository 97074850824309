import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { Nav, FormControl, Row, Col, Container, InputGroup, Dropdown, Navbar, Button } from 'react-bootstrap';
import logo from './Images/logo3.png';
import logo1 from './Images/Logo1.png';
import logo_gradient from './Images/logo_gradient.png';
import logo_white from './Images/logo_white.png';
import logo_black from './Images/logo_black.png';
import logo_navy from './Images/logo_navy.png';
import { useAuth } from './AuthContext';
import './Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // If using Font Awesome for icons
import SearchUsers from './SearchUsers';
import { faHome, faHandshake, faQuestion, faUser, faSwimmer, faFileInvoiceDollar, faSignOut, faChartLine, faCreditCard, faCheck, faSearch, faBell, faPlus, faGear } from '@fortawesome/free-solid-svg-icons'; // Example icons
import { useMediaQuery } from 'react-responsive';
import { ProfilePicture } from './MiscComponents';
import { timeSince } from './CriticalFunctions';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useErrorBoundary } from "react-error-boundary";
import { StyledNavbar, StyledDropdown, StyledButton, StyledH5, StyledNavLink } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import { myAxiosInstance } from './axiosConfig';
import TutorialModal from './TutorialModal';
import Notifications from './Notifications';


function NavbarLargeScreen({ setShowReferModal, setWhyModalShow }) {
    const themeMode = useTheme();
    const navigate = useNavigate();
    const { userSlug, handleLogout, userFirstName, userLastName, userGraduationDate, profilePictureLink } = useAuth();

    const logOutNavigate = () => {
        handleLogout();
        navigate('/signing-out');

    }

    const getBaseFontSize = () => {
        // Get the font-size of the body element
        const fontSize = window.getComputedStyle(document.body).fontSize;
        // Parse the font size and return it as a number
        return parseFloat(fontSize);
    };



    return (
        <StyledNavbar expand="sm" sticky='top'>
            <Container fluid>
                <div className='w-100 d-flex justify-content-between'>
                    
                        <Navbar.Brand className="d-flex ml-2 align-items-center" onClick={() => navigate('/home')} style={{ cursor: 'pointer' }}>
                            <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem" className="d-inline-block align-top" alt="Logo" />
                        </Navbar.Brand>
                    

                    <div className='w-50'>
                        <SearchUsers />
                    </div>

                    <div className="d-flex align-items-center">

                        {/*<StyledButton onClick={() => setWhyModalShow(true)} style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", height: "60%", marginRight: "0.5em", paddingInline: "0.8em", backgroundColor: themeMode.theme==='dark' ? themeMode.onSurface : themeMode.primary, color: themeMode.theme==='dark' ? themeMode.surface : themeMode.onPrimary}}>Why?</StyledButton>*/}
                        {/*<StyledButton className='invite-joyrride' onClick={() => setShowReferModal(true)} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", height: "60%", marginRight: "0.5em", paddingInline: "0.8em", backgroundColor: themeMode.theme === 'dark' ? themeMode.onSurface : themeMode.primary, color: themeMode.theme === 'dark' ? themeMode.surface : themeMode.onPrimary }}>Invite</StyledButton>*/}
                        <Notifications />
                        <StyledDropdown >
                            <StyledDropdown.Toggle as={Nav.Link} className="no-caret-icon profile-dropdown">
                                {/*<FontAwesomeIcon icon={faUser} />*/}
                                <div className='profile-dropdown-joyrride'>
                                    <ProfilePicture name={userFirstName + " " + userLastName} size={getBaseFontSize() * 3} graduation_date={userGraduationDate} picture_link={profilePictureLink} />
                                </div>
                            </StyledDropdown.Toggle>
                            <StyledDropdown.Menu align={'end'}>
                                <StyledDropdown.Item as={Link} to={"user/" + userSlug} >
                                    <Row>
                                        <Col xs={2}><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></Col>
                                        <Col>Profile</Col>

                                    </Row>
                                </StyledDropdown.Item>

                                <StyledDropdown.Item as={Link} to="settings">
                                    <Row>
                                        <Col xs={2}><FontAwesomeIcon icon={faGear}></FontAwesomeIcon></Col>
                                        <Col>Settings</Col>

                                    </Row>
                                </StyledDropdown.Item>

                                <StyledDropdown.Item as={Link} to="faqs">
                                    <Row className='faqs-joyrride'>
                                        <Col xs={2}><FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon></Col>
                                        <Col>FAQs</Col>

                                    </Row>
                                </StyledDropdown.Item>
                                <StyledDropdown.Item onClick={logOutNavigate}>
                                    <Row>
                                        <Col xs={2}><FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon></Col>
                                        <Col>Logout</Col>

                                    </Row>
                                </StyledDropdown.Item>
                            </StyledDropdown.Menu>
                        </StyledDropdown>
                    </div>
                </div>
            </Container>

        </StyledNavbar>);
}


export default NavbarLargeScreen;