import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { stringToColor } from './CriticalFunctions';
import { formatGraduationDate } from './CriticalFunctions';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { StyledDropdown, StyledForm, StyledFormGroup, StyledFormControl } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import { myAxiosInstance } from './axiosConfig';
import { ProfilePicture } from './MiscComponents';

const bubbleStyle = (color) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '5px', // Adjust padding for less height
    margin: '0px',
    borderRadius: '0px',
    color: 'black',
    fontSize: '0.8rem',
    cursor: 'pointer',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    width: '100%'
});

const profilePictureStyle = {
    width: '40px', // Adjust width for less height
    height: '40px', // Adjust height for less height
    borderRadius: '50%',
    marginRight: '10px'
};

const customDropdownItemStyle = {
    width: '100%',
    backgroundColor: 'white',
    border: 'none',
    display: 'block',
    padding: '5px', // Adjust padding for less height
    cursor: 'pointer'
};

const customDropdownItemHoverStyle = {
    backgroundColor: '#e0e0e0'
};

const handleMouseEnter = (e) => {
    e.currentTarget.style.backgroundColor = customDropdownItemHoverStyle.backgroundColor;
};

const handleMouseLeave = (e) => {
    e.currentTarget.style.backgroundColor = customDropdownItemStyle.backgroundColor;
};

function SearchUsers() {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showDropdown, setShowDropdown] = useState(false);
    const wrapperRef = useRef(null);

    const navigate = useNavigate();
    const { userSlug } = useAuth();
    const themeMode = useTheme();

    const fetchOptions = async (query) => {
        try {
            const response = await myAxiosInstance.get(`/api/search-users/?q=${query}&limit=20`);
            console.log(response);
            return response.data.users;
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowDropdown(false);
                setInputValue('');
                setActiveSuggestionIndex(0);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (inputValue) {
                fetchOptions(inputValue).then(data => {
                    setSuggestions(data);
                });
            } else {
                setSuggestions([]);
            }
        }, 100);

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [inputValue]);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setShowDropdown(true);
        setActiveSuggestionIndex(0);
    };

    const handleSelect = (item) => {
        setInputValue('');
        setActiveSuggestionIndex(0);
        setShowDropdown(false);
        openUser(item.slug);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && suggestions.length > 0) {
            event.preventDefault();
            handleSelect(suggestions[activeSuggestionIndex]);
        } else if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
            setActiveSuggestionIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
        } else if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
            setActiveSuggestionIndex((prevIndex) => (prevIndex + suggestions.length - 1) % suggestions.length);
        }
    };

    const openUser = (slug) => {
        navigate('/dashboard/user/' + slug + '/');
    };

    return (
        <>
            <StyledForm className='mt-3 search-joyrride w-100'>
                <StyledFormGroup ref={wrapperRef} className='d-flex flex-column'>
                    <StyledFormControl
                        type="text"
                        value={inputValue}
                        placeholder={"Search Users"}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        style={{ minWidth: '320px' }} // Adjust the width here
                        className='search-users-small'
                        noShadow
                    />
                    <StyledDropdown show={showDropdown && suggestions.length > 0}>
                        <StyledDropdown.Menu align={'start'} style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: '100%',
                            width: '100%', // Ensure dropdown menu spans 120% width of the search box
                            maxHeight: '400px', // Set max height for scrolling
                            overflowY: 'auto' // Enable vertical scrolling
                        }}>
                            {suggestions.map((item, index) => (
                                <StyledDropdown.Item
                                    key={index}
                                    active={index === activeSuggestionIndex}
                                    onClick={() => handleSelect(item)}
                                    style={{
                                        ...customDropdownItemStyle,
                                        ...(index === activeSuggestionIndex ? customDropdownItemHoverStyle : {})
                                    }}
                                    className={`${index === activeSuggestionIndex ? 'active-suggestion' : ''}`}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <div style={{
                                        ...bubbleStyle(stringToColor(item.first_name + " " + item.last_name))
                                    }}>
                                        {/*<img src={item.profile_picture} alt="Profile" style={profilePictureStyle} />*/}
                                        <img
                                            src={item.profile_picture ? item.profile_picture : 'https://storage.googleapis.com/prosp_profile_img/profile_pictures/main_profile_picture_prospinity-captain-O4zW.png'}
                                            alt={"Preview"}
                                            onError={(e) => { e.target.src = 'https://storage.googleapis.com/prosp_profile_img/profile_pictures/main_profile_picture_prospinity-captain-O4zW.png'; }}
                                            style={profilePictureStyle}
                                        />
                                        <span>
                                            {item.first_name + " " + item.last_name}
                                            {` '${formatGraduationDate(item.graduation_date)}, ${item.university}, ${item.major_or_grad_program.map(program => program).join(", ")}`}
                                            {item.company ? `, ${item.company}` : ""}
                                        </span>
                                        
                                    </div>
                                </StyledDropdown.Item>
                            ))}
                        </StyledDropdown.Menu>
                    </StyledDropdown>
                </StyledFormGroup>
            </StyledForm>
        </>
    );
}

export default SearchUsers;
