//graceful error handling for unknown user
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Container, Card, Button, Image, Table, Form } from 'react-bootstrap';
import { ProfilePicture } from './MiscComponents';
import { useErrorBoundary } from "react-error-boundary";
import { StyledCard, StyledH5, StyledParagraph, StyledButton, StyledBorderCard } from './StyledBootstrap';
import { myAxiosInstance } from './axiosConfig';
import { useAuth } from './AuthContext';
import { useTheme } from 'styled-components';
import DeleteMeetingModal from './DeleteMeetingModal';
import MeetingTimer from './MeetingTimer';
import { useNavigate } from 'react-router-dom';


const RequestPreviewCard = ({ meeting, bottomReload, setBottomReload }) => {

    const navigate = useNavigate();

    console.log("MEETING PREVIEW", meeting);

    const themeMode = useTheme();

    const [deleteShow, setDeleteShow] = useState(false);
    const [pastTime, setPastTime] = useState(false);
    const [bottom1Reload, setBottom1Reload] = useState(0);



    useEffect(() => {
        const checkPastTime = async () => {
            if (meeting.user_meeting_status === "confirmed" || meeting.user_meeting_status === "in_progress") {

                const now = new Date();
                const deadlineDate = new Date(meeting.scheduled_day);
                const timeDiff = deadlineDate - now;
                if (timeDiff <= 0) {
                    setPastTime(true);
                }
            }
        };

        checkPastTime();


    }, [bottom1Reload]);

    
    const cardRef = useRef(null);

    const profilePictureStyle = {
        width: '4rem', // Adjust width for less height
        height: '4rem', // Adjust height for less height
        borderRadius: '50%',
        marginTop: '0.5rem',
        marginRight: '1rem',
    };

    const handleHeaderClick = () => {
        navigate('/dashboard/meeting/' + meeting.slug);
      };


    return (
        <>        
            <StyledBorderCard
                ref={cardRef}
                style={{
                    width: '21rem',
                    transition: 'transform 0.3s ease',
                    transform: 'scale(1)',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    marginBottom: '0rem',
                    cursor: 'pointer'
                }}
                onMouseEnter={() => {
                    cardRef.current.style.transform = 'scale(1.03)';
                    cardRef.current.style.boxShadow = '0 0 20px rgba(0,0,0,0.2)';
                }}
                onMouseLeave={() => {
                    cardRef.current.style.transform = 'scale(1)';
                    cardRef.current.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
                }}
                onClick={handleHeaderClick}
                >
                <StyledBorderCard.Body style={{ marginTop: '-1.0rem' }}>
                    <div className='d-flex flex-column justify-content-center'>
                    <div className="d-flex align-items-center">
                        <img
                            src={meeting.other_user.profile_picture ? meeting.other_user.profile_picture : 'https://storage.googleapis.com/prosp_profile_img/profile_pictures/main_profile_picture_prospinity-captain-O4zW.png'}
                            alt={"Preview"}
                            onError={(e) => { e.target.src = 'https://storage.googleapis.com/prosp_profile_img/profile_pictures/main_profile_picture_prospinity-captain-O4zW.png'; }}
                            style={profilePictureStyle}
                            onClick={(event) => {
                                event.stopPropagation();  // Prevent the click from bubbling to the card's onClick
                                navigate('/dashboard/user/' + meeting.other_user.slug);
                            }}
                        />
                        <div className='d-flex flex-column justify-content-center' style={{ minWidth: 0, width: '100%'}}>
                            <div style={{
                                cursor: 'pointer',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                fontSize: '1.3rem',
                                marginTop: '0.3em',
                                minWidth: 0
                            }}>
                                <strong
                                style={{
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                    textDecoration: 'none',
                                    color: 'inherit',
                                }}
                                onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
                                onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                                onClick={(event) => {
                                    event.stopPropagation();  // Prevent the click from bubbling to the card's onClick
                                    navigate('/dashboard/user/' + meeting.other_user.slug);
                                }}
                                >
                                {meeting.other_user.first_name} {meeting.other_user.last_name}
                                </strong>
                            </div>
                            
                
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '2.5rem' }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '-0.1rem' }}>
                                    <div style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        minWidth: 0,
                                        fontSize: '1.0rem',
                                    }}>
                                        {meeting.other_user.university}
                                        {meeting.other_user.company ? (
                                        <>
                                            <span style={{
                                            margin: '0 0.2rem',
                                            lineHeight: '1',
                                            display: 'inline-block',
                                            verticalAlign: 'middle',
                                            transform: 'translateY(-10%)'
                                            }}>•</span>
                                            {meeting.other_user.company}
                                        </>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    </div>
            
                    <div style={{ marginTop: '1rem', fontSize: '1rem' }}>
                        {meeting.user_meeting_status === "confirmed" ? <>Meeting confirmed on {new Date(meeting.scheduled_day).toLocaleDateString()}.</> : <></>}
                        {meeting.user_meeting_status === "in_progress" ? <>Meeting {pastTime ? <>started.</> : <> in <MeetingTimer meetingTime={meeting.scheduled_day} onTime={() => { setBottom1Reload(bottom1Reload + 1) }} />.</>} Join below!</> : <></>}
                        {meeting.user_meeting_status === "you_received" ? <>Wants to add you to their portfolio.</> : <></>}
                        {meeting.user_meeting_status === "other_user_rescheduled" ? <>Wants to reschedule.</> : <></>}
                    </div>
            
                    <div className='w-100 d-flex justify-content-center mt-3'>
                            {meeting.user_meeting_status === "in_progress" || meeting.user_meeting_status === "confirmed" ? <>
                                <StyledButton style={{ fontSize: '0.8rem', minWidth: '100%' }} onClick={() => navigate('/dashboard/meeting/' + meeting.slug)}>
                                    {meeting.user_meeting_status === "confirmed" ? <><MeetingTimer meetingTime={meeting.scheduled_day} onTime={() => { setBottom1Reload(bottom1Reload + 1) }} /></> : <></>}
                                    {meeting.user_meeting_status === "in_progress" ? <>Join Now</> : <></>}
                                </StyledButton>
                            </> :
                                <>
                                    <StyledButton className='mr-1' style={{ fontSize: '0.8rem', minWidth: '50%' }} onClick={() => navigate('/dashboard/meeting/' + meeting.slug)}>
                                        {meeting.user_meeting_status === "you_received" ? <>Accept</> : <></>}
                                        {meeting.user_meeting_status === "other_user_rescheduled" ? <>Reschedule</> : <></>}
                                    </StyledButton>
                                    <StyledButton 
                                        className='ml-1' 
                                        onClick={(event) => {
                                            event.stopPropagation();  // Prevent the click from bubbling to the card's onClick
                                            setDeleteShow(true);
                                        }} 
                                        style={{ backgroundColor: themeMode.background, color: themeMode.onBackground, fontSize: '0.8rem', minWidth: '50%' }}
                                    >
                                    Ignore
                                    </StyledButton>
                                </>}
                        </div>

                    </div>
                </StyledBorderCard.Body>
            </StyledBorderCard>
            <DeleteMeetingModal show={deleteShow} setShow={setDeleteShow} slug={meeting.slug} name={meeting.other_user.first_name} bottomReload={bottomReload} setBottomReload={setBottomReload} />
        </>
      );





    

    return (
        <>
            <StyledBorderCard ref={cardRef} style={{
                width: '17rem',
                transition: 'transform 0.3s ease',
                transform: 'scale(1)',
                boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                marginBottom: '0rem', // Adjusted margin bottom to reduce space between cards
                cursor: 'pointer'

            }}
                onMouseEnter={() => {
                    cardRef.current.style.transform = 'scale(1.03)';
                    cardRef.current.style.boxShadow = '0 0 20px rgba(0,0,0,0.2)';
                }}
                onMouseLeave={() => {
                    cardRef.current.style.transform = 'scale(1)';
                    cardRef.current.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
                }}>
                <StyledBorderCard.Body>
                    <div className='d-flex flex-column align-items-center my-1'

                    >
                        <div className='d-flex justify-content-between' style={{ minWidth: 0, width: '100%' }}>
                            <img
                                src={meeting.other_user.profile_picture ? meeting.other_user.profile_picture : 'https://storage.googleapis.com/prosp_profile_img/profile_pictures/main_profile_picture_prospinity-captain-O4zW.png'}
                                alt={"Preview"}
                                onError={(e) => { e.target.src = 'https://storage.googleapis.com/prosp_profile_img/profile_pictures/main_profile_picture_prospinity-captain-O4zW.png'; }}
                                style={profilePictureStyle}
                            />
                            <div className='d-flex flex-column justify-content-center' style={{ minWidth: 0, width: '100%' }}>
                                <div style={{
                                    cursor: 'pointer',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    fontSize: '1.3rem',
                                    marginTop: '0.3em',
                                    minWidth: 0
                                }}>
                                    <strong style={{
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        textDecoration: 'none',
                                        color: 'inherit',
                                    }}
                                        onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
                                        onMouseLeave={(e) => e.target.style.textDecoration = 'none'} onClick={() => navigate('/dashboard/user/' + meeting.other_user.slug)}>{meeting.other_user.first_name} {meeting.other_user.last_name}</strong>
                                </div>
                                <div style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis', whiteSpace: 'nowrap', minWidth: 0
                                }}>
                                    {meeting.other_user.university}
                                </div>
                                <div style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis', whiteSpace: 'nowrap', minWidth: 0
                                }}>
                                    {meeting.other_user.company === "" ? meeting.other_user.major_or_grad_program[0] : meeting.other_user.company}
                                </div>

                            </div>
                        </div>

                        <div className='mt-2 mx-1' style={{ fontSize: '0.8rem' }}>
                            {meeting.user_meeting_status === "confirmed" ? <>Meeting confirmed on {new Date(meeting.scheduled_day).toLocaleDateString()}.</> : <></>}
                            {meeting.user_meeting_status === "in_progress" ? <>Meeting {pastTime ? <>started!</> : <> in <MeetingTimer meetingTime={meeting.scheduled_day} onTime={() => { setBottom1Reload(bottom1Reload + 1) }} /></>}. Join below!</> : <></>}
                            {meeting.user_meeting_status === "you_received" ? <>wants to add you to their portfolio</> : <></>}
                            {meeting.user_meeting_status === "other_user_rescheduled" ? <>wants to reschedule</> : <></>}
                        </div>
                        <div className='w-100 d-flex justify-content-center mx-1 mt-2'>
                            {meeting.user_meeting_status === "in_progress" || meeting.user_meeting_status === "confirmed" ? <>
                                <StyledButton style={{ fontSize: '0.8rem', minWidth: '45%' }} onClick={() => navigate('/dashboard/meeting/' + meeting.slug)}>
                                    {meeting.user_meeting_status === "confirmed" ? <><MeetingTimer meetingTime={meeting.scheduled_day} onTime={() => { setBottom1Reload(bottom1Reload + 1) }} /></> : <></>}
                                    {meeting.user_meeting_status === "in_progress" ? <>Join Now</> : <></>}
                                </StyledButton>
                            </> :
                                <>
                                    <StyledButton className='mr-1' style={{ fontSize: '0.8rem', minWidth: '45%' }} onClick={() => navigate('/dashboard/meeting/' + meeting.slug)}>
                                        {meeting.user_meeting_status === "you_received" ? <>Accept</> : <></>}
                                        {meeting.user_meeting_status === "other_user_rescheduled" ? <>Reschedule</> : <></>}
                                    </StyledButton>
                                    <StyledButton className='ml-1' onClick={() => setDeleteShow(true)} style={{ backgroundColor: themeMode.background, color: themeMode.onBackground, fontSize: '0.8rem', minWidth: '45%' }}>
                                        Ignore
                                    </StyledButton></>}

                        </div>



                    </div>
                </StyledBorderCard.Body>
            </StyledBorderCard >
            <DeleteMeetingModal show={deleteShow} setShow={setDeleteShow} slug={meeting.slug} name={meeting.other_user.first_name} bottomReload={bottomReload} setBottomReload={setBottomReload} />
        </>
    )
}
export default RequestPreviewCard;
